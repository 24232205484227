import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Prompt, Button, Checkbox, CloseButton, DropdownMenu, Icon, IconButton, Input, Media, MediaTile, Message, Slider, Spinner, Toggle } from 'components'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { createLLMContent, createLLMContext, removeLLMContext, retrieveLLMContext } from 'actions/adminActions'
import { DynamicContentType, EditorFieldType, LLMGenerateSettings, LLMGenerateType, LLMRefineSettings, MediaType, PromptType } from 'app/types'
import { DynamicContent } from './DynamicContent'
import * as fnc from 'helpers/fnc'
import { GenericEditor } from 'views/AdminPage/Views/GenericEditor'
import { pageDef } from './InlineContentEditor'
import { pushNotification, showPrompt } from 'actions/appActions'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share'
import { getPlaceholderImage } from 'helpers/config'
import { logger } from 'helpers/logger'
import { deleteLLMContext } from 'services/adminServices'

const RESULT_PAGE_COUNT = 21

const defaultContext = {
    id: null,
    label: '',
    context: '',
}

enum RetrieveType {
    SearchOnline = 'search-online',
    Library = 'library',
}

interface LLMContentHelperOptions {
    placeholder: string,
    defaultValue: string,
}

interface LLMContentHelperProps {
    app: AppData,
    organization: OrganizationData,
    id: string,
    title: string,
    value: string,
    options: LLMContentHelperOptions,
    onChange: (value: string) => void,
    onSubmit: (value: string) => void,
    onClose: () => void,
    target: HTMLElement,
    scrollElem: HTMLElement,
    top: number,
    left: number,
    type: LLMGenerateType,
    embedded: boolean,
    prompt: string,
}

const refineItems = Object.keys(LLMRefineSettings).map((x) => {
    return {
        text: x.charAt(0).toUpperCase() + x.slice(1),
        value: x.toLowerCase()
    }
})

const generateItems = Object.keys(LLMGenerateSettings).map((x) => {
    let wordCount = 0
    switch (x.toLowerCase()) {
        case 'long':
            wordCount = 100
            break
        case 'medium':
            wordCount = 50
            break
        case 'short':
            wordCount = 25
            break
    }
    return {
        text: x.charAt(0).toUpperCase() + x.slice(1) + ` (${wordCount}w)`,
        value: x.toLowerCase(),
    }
})

const testData = {
    title: 'This is a title',
    subtitle: 'This is a subtitle',
    body: 'This is the body of the content',
    elements: [
        {
            title: 'Element 1',
            body: 'This is the body of element 1',
        },
        {
            title: 'Element 2',
            body: 'This is the body of element 2',
        },
    ]
}

export function LLMContentHelper(props: LLMContentHelperProps) {
    const { app, organization, id, title, target, type, options, embedded } = { options: {}, ...props }
    const [position, setPosition] = useState<{ left: number; top: number }>(null)
    const [targetInitial, setTargetInitial] = useState<{ left: number; top: number }>(null)
    const [settings, setSettings] = useState({ directive: null, structure: [] })
    const [value, setValue] = useState(props.value)
    const [prompt, setPrompt] = useState('')
    const [temperature, setTemperature] = useState(0.5)
    const [generating, setGenerating] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [result, setResult] = useState(options?.defaultResult)
    const [resultSelected, setResultSelected] = useState(null)
    const [resultPage, setResultPage] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [submitMessage, setSubmitMessage] = useState('')
    const [error, setError] = useState(null)

    const [currentContext, setCurrentContext] = useLocalStorage('llm-context', null)
    const [editContext, setEditContext] = useState(false)
    const llmContext = useAppSelector(state => state.admin.llmContext)
    const context = currentContext ? llmContext?.find((x) => x.id === currentContext) : null
    const [contextSaving, setContextSaving] = useState(false)
    const [contextPrompt, setContextPrompt] = useState(null)
    const [mediaHelper, setMediaHelper] = useState(null)

    const allMedia = useAppSelector((state) => state.app.media)
    const dragStartRef = useRef<{ x: number; y: number; left: number; top: number }>()
    const dragOffset = useRef<{ x: number; y: number }>()
    const elemRef = useRef<HTMLDivElement>(null)
    const settingsKey = `llm-settings-${id}-${type}`
    const dispatch = useAppDispatch()

    const style: React.CSSProperties = {
        position: 'absolute',
        left: position?.left,
        top: position?.top,
        cursor: dragging ? 'grabbing' : 'grab',
    }
    useEffect(() => {
        if (llmContext != null) {
            return
        }

        dispatch(retrieveLLMContext({ app, organization }))
    }, [llmContext])

    useEffect(() => {
        let cachedSettings = localStorage.getItem(settingsKey)
        cachedSettings = cachedSettings ? JSON.parse(cachedSettings) : {}
        switch (type) {
            case LLMGenerateType.SingleField:
                setSettings({
                    directive: null,
                    structure: [],
                    ...cachedSettings
                })
                break
            case LLMGenerateType.SingleContent:
                setSettings({
                    directive: null,
                    structure: [DynamicContentType.Blurb],
                    placeholders: false,
                    ...cachedSettings
                })
                break
            case LLMGenerateType.PageContent:
                setSettings({
                    directive: null,
                    structure: [
                        DynamicContentType.Splash,
                        DynamicContentType.Blurb,
                        DynamicContentType.Accordian
                    ],
                    placeholders: false,
                    ...cachedSettings
                })
                break
            case LLMGenerateType.RetrieveImage:
                setSettings({
                    search: '',
                    directive: RetrieveType.SearchOnline
                })
                break
        }
    }, [type])

    useEffect(() => {
        if (!target) {
            setPosition({ left: props.left, top: props.top })
            return
        }

        if (targetInitial) return
        const rect = target.getBoundingClientRect()
        setTargetInitial({ left: rect.left, top: rect.top })
    }, [target, targetInitial])

    useEffect(() => {
        if (!target || !targetInitial || position) return

        function updatePosition() {
            const rect = target.getBoundingClientRect()
            // Calculate offset based on target initial, apply difference to props.left and props.top
            const offsetTop = rect.top - targetInitial.top
            const offsetLeft = rect.left - targetInitial.left
            setPosition({ left: props.left + offsetLeft, top: props.top + offsetTop })
        }

        updatePosition() // Initial positioning

        // // Scroll and resize listeners
        // window.addEventListener('scroll', updatePosition, true)
        // window.addEventListener('resize', updatePosition)
        // if (props.scrollElem) {
        //     props.scrollElem.addEventListener('scroll', updatePosition)
        // }

        // // MutationObserver to track DOM changes
        // const observer = new MutationObserver(updatePosition)
        // observer.observe(document.body, { attributes: true, childList: true, subtree: true })

        // return () => {
        //     window.removeEventListener('scroll', updatePosition, true)
        //     window.removeEventListener('resize', updatePosition)
        //     observer.disconnect() // Clean up observer
        //     if (props.scrollElem) {
        //         props.scrollElem.removeEventListener('scroll', updatePosition)
        //     }
        // }
    }, [target, targetInitial])

    function getContextEdited() {
        return (context && editContext && context.id == editContext.id && JSON.stringify(context) != JSON.stringify(editContext)) || (editContext && !editContext.id)
    }

    function handleContextSafeAction(action, data) {
        if (getContextEdited()) {
            setContextPrompt({ type: PromptType.Confirm, title: 'Discard Changes?', message: 'You have unsaved changes. Do you want to discard them?', action, data })
        } else {
            handleContextAction(action, data)
        }
    }

    function handleAddContext() {
        const newContext = { ...defaultContext }
        handleContextSafeAction('add', newContext)
    }

    function handleChangeContext(x) {
        handleContextSafeAction('change', x)
    }

    function handleEditContextField(field, value) {
        // Check for change
        const newEditContext = { ...editContext }
        newEditContext[field] = value
        setEditContext(newEditContext)
    }

    function handleEditContext(x) {
        handleContextSafeAction('edit', x)
    }

    function handleSaveContext() {
        setContextSaving(true)
        dispatch(createLLMContext({ app, organization, data: editContext }))
            .then((x) => {
                setContextSaving(false)
                setCurrentContext(x.payload.data.id)
                setEditContext(null)
            })
    }

    function handleCancelContextEdit() {
        handleContextSafeAction('cancel')
    }


    function handleDeleteContext() {
        setContextPrompt({ type: PromptType.Confirm, title: 'Delete Context?', message: `Are you sure you want to delete the context "${editContext?.label}"? This action cannot be undone.`, action: 'delete' })
    }

    function handleCloneContext() {
        handleContextSafeAction('clone')

    }

    function handleContextAction(action, data = null) {
        switch (action) {
            case 'cancel':
                setEditContext(null)
                break
            case 'delete':
                setContextSaving(true)
                dispatch(removeLLMContext({ app, organization, data: editContext }))
                    .then((x) => {
                        setContextSaving(false)
                        setEditContext(null)
                        setCurrentContext(null)
                    })
                break
            case 'change':
                setCurrentContext(data.id)
                break
            case 'edit':
                if(data) {
                    const match = llmContext.find((x => x.id == data))
                    setEditContext({ ...match })
                    setCurrentContext(data)
                } else {
                    if (context) {
                        setEditContext({ ...context })
                    } else {
                        setEditContext({ ...defaultContext })
                    }
                }
                break
            case 'add':
                setCurrentContext(null)
                setEditContext(data)
                break
            case 'clone':
                const clonedContext = { ...context, id: null, label: `${context.label} (copy)` }
                setEditContext(clonedContext)
                break
        }

    }

    function handleContextPromptResponse(response: boolean) {
        if (response) {
            handleContextAction(contextPrompt.action)
        }
        setContextPrompt(null)
    }

    function handleMouseDown(event: React.MouseEvent) {
        if (!elemRef.current) return
        setDragging(true)
        dragOffset.current = {
            x: event.clientX - position.left,
            y: event.clientY - position.top,
        }
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    function handleMouseMove(event: MouseEvent) {
        if (!dragOffset.current) return
        setPosition({
            left: event.clientX - dragOffset.current.x,
            top: event.clientY - dragOffset.current.y,
        })
    }

    function handleMouseUp() {
        dragOffset.current = null
        setDragging(false)
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    function cacheSettings(newSettings) {
        localStorage.setItem(settingsKey, JSON.stringify(newSettings))
        setSettings(newSettings)

    }

    function handleSetting(field, value) {
        const newSettings = { ...settings }
        newSettings[field] = value
        cacheSettings(newSettings)
    }

    function handlePlaceholders(placeholders) {
        handleSetting('placeholders', placeholders)
    }

    function handleDirective(directive: string) {
        handleSetting('directive', directive)
    }

    function handleStructure(item: string, ix = null) {
        const newSettings = { ...settings }
        newSettings.structure = [...settings.structure]
        if (ix !== null) {
            newSettings.structure[ix] = item
        }
        cacheSettings(newSettings)
    }

    function handleRemoveStructure(ix) {
        const newSettings = { ...settings }
        newSettings.structure = [...settings.structure]
        newSettings.structure.splice(ix, 1)
        cacheSettings(newSettings)
    }

    function handleAddStructure() {
        const newSettings = { ...settings }
        newSettings.structure = [...settings.structure]
        newSettings.structure.push(DynamicContentType.Blurb)
        cacheSettings(newSettings)
    }

    function handlePrompt(x) {
        setPrompt(x)
    }

    function handleLibrary() {
        let media = []
        if (app) {
            media = Object.values(allMedia).filter(x => x.appId == app.meta.id)
        } else if (organization) {
            media = Object.values(allMedia).filter(x => x.organizationId == organization.id)
        }
        media = media.filter((x) => x.mediaTypeId == MediaType.Image)
        setResult(media)
    }

    async function handleGenerate() {
        setGenerating(true)
        setError(true)
        // if(!embedding) 
        // setResult(null)
        const data = {
            prompt: prompt,
            options: { id, temperature, instruction: context?.context },
            type,
        }
        if (value) {
            data.options.initial = value
        }
        if (settings) {
            data.options = { ...data.options, ...settings }
        }

        if (type == LLMGenerateType.SocialMediaPost) {
            // Create filtered data that only uses title, subtitle, body, and elements keys
            data.options.input = fnc.filterObject(options.data, ['title', 'subtitle', 'body', 'elements'])
        }

        const ret = await dispatch(createLLMContent({ data, app, organization }))
        setGenerating(false)
        if (ret.meta.requestStatus != 'fulfilled') {
            setError('Error generating content')
        } else {
            let newResult = 'content' in ret.payload ? ret.payload.content : ret.payload
            const imageResults = {}

            if (type == LLMGenerateType.PageContent || type == LLMGenerateType.SingleContent) {
                let proms = []
                let replaceMedia = []
                if (settings.placeholders) {
                    const placeholderMedia = Object.values(allMedia).find(x => {
                        if (x.link != 'placeholder.jpg') return false
                        if (app && x.appId != app.meta.id) return false
                        if (organization && x.organizationId == organization.id) return false
                        return true
                    })
                    replaceMedia = (x) => {
                        if (placeholderMedia) {
                            x.media = [{ mediaId: placeholderMedia.id, mediaTypeId: MediaType.Image }]
                        } else {
                            // If not use hardcoded placeholder
                            let placeholderUrl = getPlaceholderImage()
                            x.media = [{ url: placeholderUrl, mediaTypeId: MediaType.Image }]
                        }
                    }
                } else {
                    replaceMedia = (x) => {
                        return dispatch(createLLMContent({ data: { prompt: x.media, type: LLMGenerateType.RetrieveImage }, app, organization }))
                            .then((y) => {
                                const urls = y.payload
                                const randomImage = urls[Math.floor(Math.random() * urls.length)]
                                x.media = [{ url: randomImage, mediaTypeId: MediaType.Image, prompt: x.media, result: urls }]
                            })
                    }
                }
                const fixContent = (x) => {
                    if (x.media && typeof x.media == 'string') {
                        proms.push(replaceMedia(x))
                    }
                    if (x.elements) {
                        x.elements.forEach(fixContent)
                    }
                }
                newResult.forEach(fixContent)
                await Promise.all(proms)
            }

            // Strip quotes and whitespace
            if (typeof newResult === 'string') {
                newResult = newResult.replace(/["']/g, '')
            } else if (Array.isArray(newResult) && type == LLMGenerateType.SingleContent) {
                newResult = newResult[0]
            }

            setResult(newResult)
        }
    }

    function handleClose() {
        if (mediaHelper) {
            handleMediaHelperClose()
            return
        }
        if (props.onClose) {
            props.onClose()
        }
    }

    function handleChange(x) {
        setValue(x)
        if (props.onChange) {
            props.onChange(x)
        }
    }

    async function handleSubmit(x) {
        if (props.onSubmit) {
            setSubmitting(true)
            const ret = await props.onSubmit(x ? x : value)
            setSubmitting(false)
            if (ret != null) {
                // Display message, then close
                setSubmitMessage(ret)
                await new Promise(resolve => setTimeout(resolve, 1000)) // 1 second delay
            }
            handleClose()
        }
    }

    function handleReject() {
        setResult(null)
    }

    function handleRefine() {
        handleChange(result)
        setResult(null)
    }

    function handleAccept() {
        // setResult(null)
        // handleChange(result)
        if (type == LLMGenerateType.SingleContent || type == LLMGenerateType.PageContent) {
            handleSubmit(result)
        } else if (type == LLMGenerateType.RetrieveImage) {
            if (Array.isArray(result) && resultSelected != null) {
                handleSubmit(result[resultSelected])
            }
        } else {
            handleSubmit(result)
            // handleChange(result)
            // setResult(null)
        }
    }

    function handleMedia(x, e) {
        const newMediaHelper = {
            id: 'media-helper',
            type: LLMGenerateType.RetrieveImage,
            target: e.target,
            title: 'Search for image',
            top: e.pageY,
            left: e.pageX,
            options: { defaultValue: x.prompt, defaultResult: x.result },
            media: x
        }
        setMediaHelper(newMediaHelper)
    }

    function handleMediaHelperSubmit(x) {
        const newResult = JSON.parse(JSON.stringify(result).replace(mediaHelper.media.url, x))
        setResult(newResult)
    }

    function handleMediaHelperClose() {
        setMediaHelper(null)
    }

    function handleCopy(value, key) {
        if (fnc.copyToClipboard(value)) {
            dispatch(pushNotification({ message: key + ' copied to clipboard' }))
        } else {
            dispatch(pushNotification({ message: 'There was a problem copying to the clipboard.' }))
        }
    }


    function getResult() {
        const submitElem = <div className="row submit">
            <Button onClick={handleReject}> Reject</Button>
            {type == LLMGenerateType.SingleField && <Button onClick={handleRefine}> Refine</Button>}
            <Button tertiary onClick={handleAccept}>Accept</Button>
        </div>
        switch (type) {
            case LLMGenerateType.SingleField:
                return <div className="column result">
                    <h5>Generated Content</h5>
                    <div className="row">
                        <Input textarea value={result} onChange={setResult} />
                    </div>
                    {submitElem}
                </div>
            case LLMGenerateType.SingleContent:
                return <div className="column result">
                    <h5>Generated Content</h5>
                    <div className="row result-content scrollable">
                        <DynamicContent dynamicContentTypeId={settings.structure[0]} onMedia={handleMedia} {...result} app={app} organization={organization} />
                    </div>
                    {submitElem}
                </div>
            case LLMGenerateType.PageContent:
                return <div className="column result">
                    <h5>Generated Content</h5>
                    <div className="row result-content scrollable">
                        <div className="column">
                            {result.map((x, ix) => {
                                return <DynamicContent dynamicContentTypeId={x.dynamicContentTypeId || x.type} onMedia={handleMedia} {...x} app={app} organization={organization} />
                            })}
                        </div>
                    </div>
                    {submitElem}
                </div>
            case LLMGenerateType.RetrieveImage:
                // Strip query string
                let finalResult = result
                let paginate = false
                if (finalResult.length > RESULT_PAGE_COUNT) {
                    const startIndex = Math.min(result.length, RESULT_PAGE_COUNT * resultPage);
                    const endIndex = Math.min(result.length, startIndex + RESULT_PAGE_COUNT);
                    finalResult = finalResult.slice(startIndex, endIndex);
                    paginate = true
                }
                if (settings.search && settings.search.length > 0) {
                    finalResult = finalResult.filter((x) => {
                        if (x.name && x.name.toLowerCase().includes(settings.search.toLowerCase())) {
                            return true; // Keep this item if it matches the search
                        }
                        if (x.url && x.url.toLowerCase().includes(settings.search.toLowerCase())) {
                            return true; // Keep this item if the URL matches the search
                        }
                        return false
                    })
                }
                return <div className="column result">
                    <h5>Pick Image</h5>
                    <div className="row">
                        <Input value={settings.search} clear onChange={(x) => handleSetting('search', x)} placeholder="Search for images" type="text" />
                    </div>
                    <div className="row result-content scrollable" key={resultPage}>
                        {finalResult.map((x, ix) => {
                            if (typeof x == 'string') {
                                const finalUrl = x//x.replace(/\?.*$/, '')
                                const media = { mediaTypeId: MediaType.Image, url: finalUrl, name: 'Image ' + (ix + 1) }
                                return <MediaTile selected={ix == resultSelected} media={media} onClick={() => setResultSelected(ix)} hoverShow={true} />
                            } else {
                                // Handle case where result is an object with a url property
                                return <MediaTile selected={ix == resultSelected} app={app} organization={!app ? organization : null} media={x} onClick={() => setResultSelected(ix)} hoverShow={true} />
                            }
                            // return <Media url={finalUrl} onClick={() => setResultSelected(ix)}/>
                        })}
                        {paginate && <div className="row result-content-pagination">
                            <Button onClick={() => setResultPage(resultPage - 1)} disabled={resultPage <= 0}>&lt;</Button>
                            <Button onClick={() => setResultPage(resultPage + 1)} disabled={resultPage >= Math.ceil(result.length / RESULT_PAGE_COUNT) - 1}>&gt;</Button>
                        </div>}
                    </div>
                    {resultSelected != null && submitElem}
                </div>
            case LLMGenerateType.SocialMediaPost:
                return <div className="column result">
                    <h5>Result</h5>
                    <div className="row result-content scrollable">
                        <div className="column">
                            {result.map((x, ix) => {
                                return <div className="row">
                                    <div className="column">
                                        <DynamicContent dynamicContentTypeId={DynamicContentType.Blurb} {...x} />
                                        <div className="row copy-options">
                                            <Button tertiary onClick={() => handleCopy(x.title, 'Title')}>Copy Title</Button>
                                            <Button tertiary onClick={() => handleCopy(x.body, 'Body')}>Copy Body</Button>
                                        </div>
                                    </div>
                                </div>

                            })}
                        </div>
                    </div>
                    {/* {submitElem} */}
                </div>
        }
    }

    function getContextEditor() {
        if (contextPrompt) {
            return <Prompt prompt={contextPrompt} onDismiss={handleContextPromptResponse} />
        }

        const isBlank = Object.keys(editContext).length === 0
        return <div className={`row context${contextSaving ? ' disabled' : ''}`} key={editContext?.id}>
            <div className="column">
                <h4>{context ? 'Edit Context' : 'Set New Context'}</h4>
                {llmContext.length > 0 && <div className="row context-select">
                    {editContext?.id && <DropdownMenu items={llmContext.map((x, ix) => ({ text: x.label, value: x.id }))} value={currentContext} onChange={(x) => handleEditContext(x)} />}
                    {editContext?.id && <IconButton icon="fas fa-plus" onClick={handleAddContext} />}
                </div>}
                <Input label="label" placeholder="Context label" value={editContext?.label} onChange={(x) => handleEditContextField('label', x)} />
                <Input textarea placeholder="Here you can define the context for which the AI should work within" defaultValue={editContext?.context} onChange={(x) => handleEditContextField('context', x)} />
                <div className="row context-options">
                    <Button secondary icon="fas fa-save" disabled={isBlank} onClick={handleSaveContext}>Save</Button>
                    <Button icon="fas fa-times" onClick={handleCancelContextEdit}>Cancel</Button>
                    {context && <IconButton icon="fas fa-trash" onClick={handleDeleteContext}/>}
                    {context && <IconButton icon="fas fa-copy" onClick={handleCloneContext}/>}
                </div>
                {contextSaving && <Spinner />}
            </div>
        </div>
    }

    function getGeneratorInput() {
        let inputElem = null

        const generateText = type == LLMGenerateType.RetrieveImage ? ['Getting Images...', 'Get Image'] : ['Generating...', 'Generate']
        const submitElem = <div className="row submit">
            {generating && <Button tertiary disabled>{generateText[0]}</Button>}
            {!generating && <Button tertiary onClick={handleGenerate}>{generateText[1]}</Button>}
        </div>

        const titleElem = <div className="row">
            <h5 className="noselect">{title ? title : 'AI Assist'}</h5>
        </div>

        let temperatureElem = <div className="row temperature">
            <div className="top-left">
                <span>Predictable</span>
            </div>
            <div className="top-right">
                <span>Creative</span>
            </div>
            <Slider simple min={0.0} max={1} step={0.1} value={temperature} onChange={setTemperature} />
        </div>
        let optionsElem = <div className="row options">
            <Checkbox value={settings.placeholders} onChange={handlePlaceholders}>Use Placeholder Images</Checkbox>
        </div>
        const availableActions = value ? refineItems : generateItems
        let actionsElem = <div className="row suggestions">
            <Toggle items={availableActions} value={settings.directive} onChange={handleDirective} toggleOff />
        </div>
        const acceptedSet = new Set([DynamicContentType.Blurb, DynamicContentType.Accordian, DynamicContentType.Splash, DynamicContentType.Numbered, DynamicContentType.Grid, DynamicContentType.Items, DynamicContentType.Steps, DynamicContentType.Tabbed])
        const typeItems = Object.keys(DynamicContentType).filter((x) => isNaN(x) && acceptedSet.has(DynamicContentType[x])).map((x) => {
            return { value: DynamicContentType[x], text: x?.toReadable() }
        })
        const contextElem = llmContext ? <div className="row context-select">
            {llmContext.length > 0 && <React.Fragment>
                <DropdownMenu items={llmContext?.map((x) => ({ text: x.label, value: x.id }))} onChange={setCurrentContext} value={currentContext} selectText="Set Context" />
                <IconButton icon={context ? "fas fa-pen" : "fas fa-plus"} onClick={() => handleEditContext()} />
            </React.Fragment>}
            {llmContext.length == 0 && <Button onClick={() => handleEditContext()}>Set Context</Button>}
        </div> : <div className="row"><Button disabled>Loading Contexts</Button></div>

        switch (type) {
            default:
            case LLMGenerateType.SingleField:
                return <div className="column generate">
                    {titleElem}
                    {contextElem}
                    {props.value && <div className="row initial">
                        <Input textarea defaultValue={value} onChange={setValue} />
                    </div>}
                    <Input textarea value={prompt} defaultValue={options.defaultValue} placeholder={options.placeholder || "Enter prompt here"} onChange={handlePrompt} />
                    {actionsElem}
                    {temperatureElem}
                    {submitElem}
                </div>
            case LLMGenerateType.SingleContent: {
                return <React.Fragment>
                    <div className="column generate">
                        {titleElem}
                        {contextElem}
                        {value && <DynamicContent {...value} />}
                        <DropdownMenu items={typeItems} onChange={(x) => handleStructure(x, 0)} value={settings.structure[0]} />
                        <Input textarea value={prompt} defaultValue={options.defaultValue} placeholder={options.placeholder || "Enter prompt here"} onChange={handlePrompt} />
                        {actionsElem}
                        {optionsElem}
                        {temperatureElem}
                        {submitElem}
                    </div>
                    {!result && <div className="column content-example result">
                        <h5>Preview</h5>
                        <div className="result-content scrollable">
                            <DynamicContent dynamicContentTypeId={settings.structure[0]} {...testData} />
                        </div>
                    </div>}
                </React.Fragment>
            }
            case LLMGenerateType.PageContent: {
                return <React.Fragment>
                    <div className="column generate">
                        {titleElem}
                        {contextElem}
                        {/* {value && <DynamicContent {...value} />} */}
                        <div className="page-sections">
                            {settings.structure?.map((x, ix) => {
                                return <div className="row page-section">
                                    <span>{`Section ${ix + 1}`}</span>
                                    <DropdownMenu items={typeItems} onChange={(y) => handleStructure(y, ix)} value={x} />
                                    <IconButton icon="fas fa-times" onClick={() => handleRemoveStructure(ix)} />
                                </div>
                            })}
                        </div>
                        <Button icon="fas fa-plus" onClick={handleAddStructure}>Add new section</Button>
                        <Input textarea value={prompt} defaultValue={options.defaultValue} placeholder={options.placeholder || "Enter prompt here"} onChange={handlePrompt} />
                        {actionsElem}
                        {optionsElem}
                        {temperatureElem}
                        {submitElem}
                    </div>
                    {!result && <div className="column content-example result">
                        <h5>Example</h5>
                        <div className="result-content scrollable">
                            {settings.structure?.map((x) => {

                                return <DynamicContent dynamicContentTypeId={x} {...testData} />
                            })}
                        </div>
                    </div>}
                </React.Fragment>
                return submitElem
            }
            case LLMGenerateType.RetrieveImage: {
                return <React.Fragment>
                    <div className="column generate">
                        {titleElem}
                        <Input value={prompt} defaultValue={options.defaultValue} placeholder={options.placeholder || "Enter prompt here"} onChange={handlePrompt} onSubmit={handleGenerate} />
                        {submitElem}
                        <h5>or</h5>
                        <Button onClick={handleLibrary}>Pick from Library</Button>
                    </div>
                </React.Fragment>
            }
            case LLMGenerateType.SocialMediaPost: {
                return <div className="column generate">
                    {titleElem}
                    <Input value={prompt} textarea defaultValue={options.defaultValue} placeholder={options.placeholder || "Enter prompt here"} onChange={handlePrompt} onSubmit={handleGenerate} />
                    {/* {optionsElem} */}
                    {actionsElem}
                    {contextElem}
                    {submitElem}
                </div>
            }
        }
    }

    if (generating) {
        style.filter = 'brightness(0.9)'
    }
    // if (!position) {
    // return null
    // }

    const bodyElem = <React.Fragment>
        {!embedded && <CloseButton onClick={handleClose} />}
        <div className="row helper-dialog">
            {editContext && getContextEditor()}
            {!editContext && getGeneratorInput()}
            {result && getResult()}
            {mediaHelper && <LLMContentHelper embedded={true} {...mediaHelper} app={app} organization={organization} onSubmit={handleMediaHelperSubmit} onClose={handleMediaHelperClose} />}
        </div>
        {error && <div className="row error"><span>{error}</span></div>}
        {(generating || submitting) && <Spinner invert={!embedded} show showAfter={null} quotes={false} />}
        {submitMessage && <div className="success"><Icon noBg icon="fas fa-check" /><h4>{submitMessage}</h4></div>}
    </React.Fragment>

    if (embedded) {
        return <div className={`column result embedded ${generating ? ' generating' : ''}`}>
            {bodyElem}
        </div>
    }

    const content = (
        <div ref={elemRef} className={`LLM-helper animate__animated animate__fadeInRight animate__faster${generating ? ' generating' : ''}${submitting ? ' submitting' : ''}`} style={style} onMouseDown={handleMouseDown} >
            {bodyElem}

        </div>
    )
    return ReactDOM.createPortal(content, document.body)
}
