import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
    CustomContentData, DynamicContentType, AppData, ThumbSize, AnalyticsEvent, PromptOptions, FloorplanFilter, CustomEventType, ScreenSize, ScreenOrientation, MarkerType, QueryType, PageType, ProjectFilter, MediaType, PromptType, ErrorMessage, UserRole
} from 'app/types'
import {
    Button, DropdownMenu, Icon, IconButton, Media, SlideShow, Map, CollapsibleRow, Tile, WaterMark, MediaIcon
} from 'components'
import { Link } from 'react-router-dom'
import { getLinkSelector, getMediaLinkSelector, useAdminPermissions, useAppDispatch, useAppSelector, useBlogPosts, useBlogTags, useDynamicContent, useExclusiveMediaPermissions, useExclusivePermissions, useHandleExclusive, useHybridContent, usePage, usePreviewMedia } from 'app/hooks'
import ReactMarkdown from 'react-markdown'
import { ContentForm, ContentView, NotFound } from 'views/AppPage'
import * as fnc from 'helpers/fnc'
import {
    analyticsEvent, navigate, navigateAsync, resetQuery, setQuery, showPrompt, submitBooking,
} from 'actions/appActions'
import { setEditContent } from 'actions/adminActions'
import { formatLocation, LocationLink } from 'views/HomePage/LocationLink'
import { logger } from 'helpers/logger'
import { ProjectTile } from 'views/HomePage/ProjectTile'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { ProjectMap } from 'views/HomePage/ProjectMap'
import { organizationMapStyles } from 'app/transformers'
import { message, pageDescription, pageIcons } from 'app/constants'
import gfm from 'remark-gfm'
import { getAppUrl, getMapsApiKey } from 'helpers/config'
import { exportMediaFile } from 'actions/adminActions'
import { ContentGallery } from 'views/AppPage/ContentGallery'
import { HubspotForm } from 'views/AppPage/HubspotForm'
import { packageFormData } from 'helpers/requestHandler'
import { setQueryHelper } from 'helpers/query'
import { getLegacyMediaId } from 'helpers/media'
import { projectSubtitle } from 'helpers/project'
import { EditButtons } from './EditButtons'

function ReactMarkdownNewline(props: any) {
    return <ReactMarkdown {...props}>
        {props.children.toMarkdown()}
    </ReactMarkdown>
}

interface DynamicContentPointProps {
    point: any,
    selected: number,
    idxA: number,
    idxB: number,
    onClick: () => void,
    onEdit: (newX: number, newY: number) => void,
    editing: boolean,
}
function DynamicContentPoint(props: DynamicContentPointProps) {
    const { point, selected, idxA, idxB, onClick, onEdit, editing } = props

    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })
    // const [dragStartPos, setDragStartPos] = useState({ x: point.x, y: point.y })
    const [currentPos, setCurrentPos] = useState({ x: point.x, y: point.y })

    let size = 30 * (point.size ? point.size : 1)
    let pointStyle = {
        left: `calc(${currentPos.x}% - ${size}px * 0.5)`,
        top: `calc(${currentPos.y}% - ${size}px * 0.5`,
    }
    let circleStyle = {
        width: `${size}px`,
        height: `${size}px`
    }

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mouseup', handleMouseUp)
            window.addEventListener('mousemove', handleMouseMove)
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [isDragging, currentPos])

    function getParentProps(e) {
        const parent = (e.target as HTMLElement).offsetParent as HTMLElement
        const parentWidth = parent.offsetWidth
        const parentHeight = parent.offsetHeight
        const parentRect = parent.getBoundingClientRect()
        const parentX = parentRect.left
        const parentY = parentRect.top
        return { parentWidth, parentHeight, parentX, parentY }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        if (editing) {
            setIsDragging(true)
            setDragStart({ x: e.clientX, y: e.clientY })
            const { parentWidth, parentHeight, parentX, parentY } = getParentProps(e)
            const screenPos = { x: parentX + parentWidth * currentPos.x / 100, y: parentY + parentHeight * currentPos.y / 100 }

            // setDragOffset({ x: e.clientX - screenPos.x - size * 0.5, y: e.clientY - screenPos.y + size * 0.5 })
            setDragOffset({ x: e.clientX - screenPos.x, y: e.clientY - screenPos.y })
            // setDragStartPos({ x: currentPos.x, y: currentPos.y })
            e.stopPropagation()
        }
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            // const dragPosition = { x: e.clientX - dragOffset.x, y: e.clientY - dragOffset.y }
            const dragPosition = { x: e.clientX, y: e.clientY }
            const { parentWidth, parentHeight, parentX, parentY } = getParentProps(e)
            const newX = ((dragPosition.x - parentX) - dragOffset.x) / parentWidth * 100
            const newY = ((dragPosition.y - parentY) - dragOffset.y) / parentHeight * 100

            setCurrentPos({ x: newX, y: newY })
        }
    }

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false)
            if (onEdit) {
                onEdit(currentPos.x, currentPos.y) // Notify parent of new position
            }
        }
    }


    function handleClick(e) {
        e.stopPropagation()
        if (editing) {
            return
        }

        if (onClick) {
            onClick(idxA)
        }
    }
    return <div
        className={`content-point animate__animated animate__bounceIn animate__delay-1s${selected == idxA ? ' selected' : ''}`}
        style={pointStyle}
        data-index={`${idxA}_${idxB}`}
        // Draggable if editing
        onMouseDown={handleMouseDown}
        // onMouseLeave={handleMouseUp}
        onClick={handleClick}>
        {/* {x.toolip &&  */}
        {<span className="circle noselect" style={circleStyle}>{(idxA + 1).toString()}</span>}
    </div>
}


interface DynamicContentProps extends CustomContentData {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    idx: number,
    onRedirect: () => void,
    onLoad: () => void,
    onMedia: () => void,
    standalone: boolean,
    staticLink: string,
    backgroundColor: string,
}

function parseBreakpoints(text) {
    return text.split('\n').map((x, ix) => <React.Fragment key={ix}>{x}</React.Fragment>).interleave((x) => <br key={`br${x}`} />)
}

export function DynamicContent(props: DynamicContentProps) {
    let page = usePage(props.pageId)
    const staticLink = props.staticLink//props.id ? null : `${page.link}-${props.idx}`
    const id = props.id ? props.id : staticLink
    const editContent = useAppSelector((state) => state.admin.editContent)
    const additionalSource = editContent?.id == id ? editContent : {}

    const {
        onLoad,
        onRedirect,
        onMedia,
        app,
        organization,
        scrollElem,
        ...customData
    } = props

    let {
        idx,
        title,
        subtitle,
        supertitle,
        header,
        footer,
        body,
        links,
        link,
        forms,
        elements,
        focus,
        contentStyle,
        full,
        dark,
        inline,
        date,
        time,
        locations,
        titleMedia,
        alignment,
        animated,
        splitIndex,
        redirect,
        standalone,
        expand,
        large,
        split,
        points,
        hubspotOptions,
        submit,
        size,
        underline,
        spacing,
        expandable,
        anchor,
        dynamicContentTypeId,
        draft,
        tags,
        backgroundColor,
    } = { animated: true, ...props, ...additionalSource }


    let contentMedia = props.media
    if (additionalSource?.media) {
        contentMedia = additionalSource.media
    }

    let location = null
    if (locations?.length > 0) {
        if (app?.locations.length > 0) {
            location = app.locations.find((x) => locations[0].locationId == x.id)
        }
    } else if (props.location) {
        location = props.location
    }

    let alt = props.alt
    if (!props.alt) {
        switch (dynamicContentTypeId) {
            case DynamicContentType.RecentBlog:
                break
            default:
                alt = contentStyle == 1
                break
        }
    }
    const dispatch = useAppDispatch()
    const adminPermissions = useAdminPermissions()
    const exclusivePermissions = useExclusivePermissions()
    const editInline = useAppSelector((state: RootState) => state.admin.editInline) && adminPermissions(app, organization, UserRole.AppsEdit)
    const config = useAppSelector((state: RootState) => state.app.config)
    const navLinks = useAppSelector((state: RootState) => state.app.links)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const user = useAppSelector((state: RootState) => state.user.data)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const getMediaLink = getMediaLinkSelector()
    const debug = useAppSelector((state: RootState) => state.app.debug)
    const currentQuery = useAppSelector((state: RootState) => (state.app.queries && QueryType.Floorplans in state.app.queries) ? state.app.queries[QueryType.Floorplans] : {})
    const previewMedia = usePreviewMedia()
    const exclusiveMediaPermissions = useExclusiveMediaPermissions()
    const handleExclusive = useHandleExclusive()
    const blogPosts = useBlogPosts()
    const getHybridContent = useHybridContent()
    const blogTags = useBlogTags()

    const editing = editContent?.id == id
    const dynamicContent = app?.dynamicContent.filter((x) => x.pageId == page?.id)
    const builder = props.builder ? props.builder : config.builders.find((x) => x.id == app?.meta?.builderId)
    let dividerBefore = false
    let dividerAfter = false

    const [initialized, setInitialized] = useState(false)
    const [data, setData] = useState(null)
    const [dataB, setDataB] = useState(null)
    const [dataC, setDataC] = useState(null)
    const [rowRef, setRowRef] = useState(null)
    const mobilePortrait = screen.isMobile && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait


    function getTypeMedia(m) {
        let finalMedia = null
        if (Array.isArray(m)) {
            finalMedia = m[0]
        } else {
            finalMedia = { ...m }
        }
        if (!finalMedia) {
            logger.error("Invalid media", m)
            return null
        }

        const type = finalMedia.mediaTypeId

        const pMedia = previewMedia(finalMedia, type)
        if (!pMedia) {
            // logger.error("No preview media", finalMedia, type)
            // console.trace()
        }
        finalMedia.mediaId = pMedia?.mediaId
        /*if (finalMedia && type != null) {
            if (type == MediaType.Tour) {
                const modelhome = app.modelhomes.find((x) => x.link == finalMedia.link || x.mediaId == finalMedia.id)
                if (modelhome) {
                    finalMedia = { ...finalMedia, mediaId: modelhome.previewMediaId }
                }
            } else if (type == MediaType.Spin) {
                const spin = app.spins.find((x) => x.views.find((x) => x.mediaId == finalMedia))
                if (spin) {
                    finalMedia = { ...finalMedia, mediaId: spin.previewMediaId }
                }
            }
        }*/
        return finalMedia
    }

    const getMediaId = (_m) => {
        if (Array.isArray(_m)) {
            return _m.map((x) => getMediaId(x))
        }
        return getLegacyMediaId(_m, app, builder, organization, allMedia, false)?.mediaId
    }

    const getMediaType = (_m) => {
        const m = Array.isArray(_m) ? _m[0] : _m
        if (m) {
            if (m.spinId) {
                return MediaType.Spin
            } else if (m.mediaId) {
                return allMedia[m.mediaId]?.mediaTypeId
            }
        }
        return null
    }

    let media = Array.isArray(contentMedia) ? contentMedia : (contentMedia != null ? [contentMedia] : [])
    const mediaIds = media && media.length > 0 ? getMediaId(media[0]) : null

    const gridSize = size != null ? (screen.isMobile ? Math.ceil(size / 3) : size) : 2
    const [gridItems, setGridItems] = useState(elements && elements.length > 0 ? elements.length : mediaIds?.length)
    const [gridSpan, setGridSpan] = useState(Math.ceil(gridSize / (screen.isMobile && screen.size == ScreenSize.Mobile ? 0.5 : 1)))
    const finalType = dynamicContentTypeId || props.type
    const [gridLimit, setGridLimit] = useState(expandable && finalType == DynamicContentType.Grid && gridItems ? Math.min(gridItems, gridSpan) : -1)

    useEffect(() => {
        // Data initialization
        switch (finalType) {
            case DynamicContentType.Developers:
            case DynamicContentType.Tabbed:
            case DynamicContentType.Steps:
                // case CustomContentType.Items:
                setData(0)
                break
            case DynamicContentType.Search:
                setData({})
                break
            case DynamicContentType.Map:
                if (!app) {
                    const projects = getProjects()
                    if (projects.length > 0) {
                        setData(projects[0])
                    }
                }
                break
            case DynamicContentType.Neighbourhood:
                if (media && media.length > 0) {
                    const m = allMedia[media[0].mediaId]
                    if (m && m.link.includes('kml')) {
                        setDataC(m)
                    }
                }
                if (media && media.length > 0 && media[0].default) {
                    setDataB(media[0].default)
                } else {
                    setDataB("1")
                }
                break
            case DynamicContentType.Points:
                setData(0)

                // Attach tooltips
                if (!screen.isMobile) {
                    setTimeout(() => {
                        const selector = `.dynamic-content[data-id="${id}"] .dynamic-content-media-points .content-point`
                        const elems = document.querySelectorAll(selector)
                        elems.forEach((x, ix) => {
                            if (!elements[ix] || !elements[ix].points) {
                                logger.error("Invalid points", ix, elements)
                                return
                            }
                            // Get index
                            const index = x.getAttribute('data-index')
                            if (index != null) {
                                const [ix, iy] = index.split('_')
                                let tooltip = elements[ix]?.title
                                if (elements[ix].points[iy]?.tooltip) {
                                    tooltip = elements[ix].points[iy]?.tooltip
                                } else if (elements[ix]?.tooltip) {
                                    tooltip = elements[ix].tooltip
                                }
                                fnc.attachTooltip(x, tooltip)
                            }
                        })
                    }, 1000)
                }
                break
            case DynamicContentType.ProjectInfo:
                setData(0)
                break
            case DynamicContentType.Form:
                setData(false)
                break
            case DynamicContentType.RecentBlog:
                let posts = blogPosts(app, organization)?.filter((x) => {
                    if (tags?.length > 0) {
                        let tagListA = tags.split(',')
                        let tagListB = x.tags ? x.tags.split(',') : []
                        return new Set(tagListA).intersection(new Set(tagListB)).size == tagListA.length
                    }
                    return true
                })
                setData(posts)
                setGridItems(posts.length)
                break
            default:
                break
        }
        setInitialized(true)
    }, [])

    useEffect(() => {
        switch (finalType) {
            case DynamicContentType.RecentBlog:
                let columnSize = 1


                let contentStyleString = ''
                let limit = 4
                let perRow = 4
                switch (contentStyle) {
                    case 0: // Row
                        perRow = 4
                        // posts = posts.slice(0, 4 * Math.max(size, 1))
                        limit = Math.max(size, 1) * 4
                        break
                    case 1: // Grid
                        perRow = 2
                        // posts = posts.slice(0, 2 * Math.max(size, 1))
                        limit = Math.max(size, 1) * 2
                        break
                    case 2: //Feature
                        perRow = 1
                        // posts = posts.slice(0, 4)
                        limit = 4
                        break
                }
                setGridLimit(limit)
                setGridSpan(perRow)
            default:
                break
        }
    }, [size, contentStyle, finalType])

    function getEditOptions() {
        if (editInline) {
            return <EditButtons id={id} staticLink={staticLink} page={page} app={app} organization={organization} data={customData} />
        }
    }

    function getDraftBadge() {
        if (draft) {
            return <div className="top-right">
                <span className="badge">Draft</span>
            </div>
        }
    }

    function handleLoad() {
        if (props.onLoad) {
            props.onLoad()
        }
    }

    const parseMedia = (_m) => {
        const m = getMediaId(_m)
        if (m.length > 0) {
            return { ..._m, mediaId: m, data: m.map((x) => allMedia[x]) }
        }
        return null
    }

    function getMedia(m, className = '', callback = null, thumbSize = ThumbSize.Large, debug = false) {
        if (!Array.isArray(m) && m.icon) {
            return <Icon icon={m.icon} noBg />
        }


        let mArr = []
        if (Array.isArray(m)) {
            mArr = m
        } else {
            mArr = [m]
        }
        if (mArr.length > 0 && !mArr[0]) {
            return null
        }

        if (onMedia) {
            callback = (a, b, c) => {
                onMedia(a, b, c)
            }
        }

        const mediaElements = []
        mArr.forEach((x) => {
            if (!x || (x.exclude != null && x.exclude == finalType)) {
                return
            }

            let ret = null
            let type = x.mediaTypeId
            if (x.mediaId) {
                type = allMedia[x.mediaId]?.mediaTypeId
                ret = x.mediaId
            } else if (x.id && !x.galleryId) {
                ret = x.id
            }

            if (type == MediaType.Tour) {
                const modelhome = app.modelhomes.find((y) => y.mediaId == x.mediaId)
                if (modelhome) {
                    ret = modelhome.previewMediaId
                }
            } else if (type == MediaType.Spin) {
                const spin = app.spins.find((y) => y.views.find((z) => z.mediaId == x.mediaId))
                if (spin) {
                    ret = spin.previewMediaId
                }
            } else if (!ret) {
                ret = getMediaId(x)
            }
            if (ret) {
                if (Array.isArray(ret)) {
                    mediaElements.push({ ...x, originalMediaId: x.mediaId, mediaId: ret })
                } else {
                    mediaElements.push({ ...x, originalMediaId: x.mediaId, mediaId: [ret] })
                }
            } else if (x.url) {
                mediaElements.push(x)
            }
        })
        let style = {}
        let fit = (mArr.length > 0 && mArr[0]) ? mArr[0].fit : 'cover'
        if (m.contain) {
            style.objectFit = 'contain'
        }
        if (!className || !className.includes('animated')) {
            // className = `${className ? className : ''} animate__animated animate__fadeIn`
        }

        const mediaIds = []
        mediaElements.forEach((x) => {
            if (Array.isArray(x.mediaId)) {
                mediaIds.push(...x.mediaId)
            } else {
                mediaIds.push(x.mediaId)
            }
        })
        return mediaElements.map((x) => {
            let finalMedia = x.mediaId
            let mediaElem = null

            if (x.url || finalMedia.length <= 1) {
                if (x.clickable && !callback) {
                    callback = (a, b, c) => {
                        handleMedia(a)
                    }
                }
                mediaElem = <Media
                    {...x}
                    fadeIn
                    app={app}
                    builder={builder}
                    organization={organization}
                    thumb={thumbSize != null}
                    thumbSize={thumbSize}
                    thumbMediaId={x.thumbnailMediaId}
                    className={className}
                    fit={fit}
                    style={style}
                    hoverShow={onMedia != null}
                    onClick={callback && (x.clickable || onMedia) ? (e) => {
                        callback(x.originalMediaId || x.mediaId || x, e)
                    } : null}
                    onLoad={handleLoad} />
            } else {
                if (x.clickable && !callback) {
                    callback = (a, b, c) => {
                        handleMedia(a, { startIndex: a.findIndex((y) => y == c) })
                    }
                }
                return <SlideShow
                    media={finalMedia}
                    app={app}
                    organization={organization}
                    zoomable={false}
                    showDots={true}
                    showIndex={false}
                    onClick={callback && (x.clickable || onMedia) ? (x, ix, e) => {
                        callback(finalMedia ? finalMedia : x, e, x)
                    } : null}
                />
            }
            let thumbMediaId = null
            if (mArr[0].thumbnailMediaId) {
                thumbMediaId = mArr[0].thumbnailMediaId
                // thumbMediaId = getMediaId(mArr[0].thumbnail)
            }
            if (callback) {
                // if (x.clickable) {
                //     return <React.Fragment>
                //         <Icon noBg icon="fas fa-hand-point-up" className="clickable-icon" style={{ display: 'none' }} />
                //         {mediaElem}
                //     </React.Fragment>
                // }
                // return <Media mediaId={mediaIds[0]} fadeIn app={app} builder={builder} organization={organization} thumb={thumbSize != null} thumbSize={thumbSize} className={className} style={style} onClick={() => callback(mediaIds[0])} />
                return mediaElem
            } else if (mArr[0].link) {
                if (mArr[0].link.includes('http')) {
                    return <a href={mArr[0].link} target="_blank" rel="noopener noreferrer">
                        {mediaElem}
                        {/* <Media mediaId={mediaIds[0]} fadeIn app={app} builder={builder} organization={organization} thumb={thumbSize != null} thumbSize={thumbSize} className={className} style={style} /> */}
                    </a>
                }
                if (organization && organization.link != 'homegyde' && !customDomain.organization) {
                    return <Link to={`/${organization.link}/${x.link}`}>
                        {mediaElem}
                    </Link>
                } else {
                    return <Link to={x.link}>
                        {mediaElem}
                    </Link>
                }
            }
            if (x.mediaTypeId == MediaType.Image && !x.clickable) {
                style.cursor = 'default'
            }

            if (x.subtitle) {
                mediaElem = <React.Fragment>
                    {mediaElem}
                    <h4>{x.subtitle}</h4>
                </React.Fragment>
            }
            return mediaElem
            // return <Media mediaId={mediaIds[0]} debug app={app} fadeIn builder={builder} organization={organization} thumb={thumbSize != null} thumbSize={thumbSize} className={className} style={style} />
        })
    }

    function getLinkUrl(l) {
        let link = null
        let checkPagePermissions = null
        if (l.pageType != null) {
            if (app) {
                const page = app.pages.find((x) => x.pageType == l.pageType)
                checkPagePermissions = page
                if (page) {
                    link = page.link
                }
            }
        } else if (l.pageId != null) {
            if (app) {
                const page = app.pages.find((x) => x.id == l.pageId)
                checkPagePermissions = page
                if (page) {
                    link = page.link
                }
            } else if (organization) {
                const page = organization.pages.find((x) => x.id == l.pageId)
                checkPagePermissions = page
                if (page) {
                    link = page.link
                }
            }
        }
        if (l.link != null) {
            if (link != null) {
                link = `${link}${l.link}`
            } else {
                link = l.link
            }
        }

        // Attach prefix if its there
        if (l.relative != null && navLinks.pageLink != null) {
            const tags = blogTags(app, organization)
            // Subpage routing when using tags
            if (tags.includes(navLinks.pageLink)) {
                link = `${navLinks.pageLink}/${link}`
            }
        }

        if (l.dataLink) {
            link = `${link}/${l.dataLink}`
        }
        return [link, checkPagePermissions]
    }

    function getLink(l, ix, _className = '') {
        let className = ''
        if (typeof _className == 'string') {
            className = _className
        }
        if (l.externalLink) {
            return <a key={ix} href={l.externalLink} target="_blank" rel="noopener noreferrer">
                <Button className={className} tertiary alt={l.alt}>{l.label}</Button>
            </a>
        }

        let [link, checkPagePermissions] = getLinkUrl(l)

        let callback = null
        let mediaCallback = false
        if (l.focus) {
            callback = () => {
                // window.dispatchEvent(new CustomEvent(CustomEventType.FocusMenu, { detail: link }))
                setTimeout(() => {
                    window.dispatchEvent(new CustomEvent(CustomEventType.MenuTutorial, { detail: { link } }))
                }, 100)
            }
        } else if (l.media) {
            const mediaId = getMediaId(l.media)
            // Determine if media is tour and if tour page is available
            const m = allMedia[mediaId]
            if (m?.mediaTypeId == MediaType.Tour) {
                const tourPage = app.pages.find((x) => x.pageType == PageType.Modelhome)
                checkPagePermissions = tourPage
            }
            if (mediaId) {
                callback = () => {
                    handleMedia(mediaId)
                }
            }
            mediaCallback = true
        } else if (l.mediaId) {
            const m = allMedia[l.mediaId]
            if (m?.mediaTypeId == MediaType.Tour) {
                const tourPage = app.pages.find((x) => x.pageType == PageType.Modelhome)
                checkPagePermissions = tourPage
            }
            callback = () => {
                handleMedia(l.mediaId)
            }
            mediaCallback = true
        } else if (l.tour) {
            callback = () => handleMedia(l.tour)
            mediaCallback = true
        }
        // if (l.link?.includes('filters:')) {
        //     callback = () => {
        //         try {
        //             const filters = JSON.parse(l.link.split('filters:')[1])
        //             const newQuery = setQueryHelper(QueryType.Floorplans, currentQuery, filters)
        //             dispatch(setQuery({ query: newQuery, type: QueryType.Floorplans }))
        //             setTimeout(() => {
        //                 // window.dispatchEvent(new CustomEvent(CustomEventType.FilterApplied, { detail: l.filters }))
        //             }, 2000)
        //         } catch (e) {
        //             logger.error(e)
        //         }
        //     }
        // }

        if (checkPagePermissions && checkPagePermissions.exclusive && !exclusivePermissions()) {
            callback = handleExclusive
            link = null
        }
        className = `${className} animate__animated animate__fadeIn`

        if (!mediaCallback && (link != null || (l.organization && customDomain.app))) {
            if (link == '#request') {
                return <Button key={ix} tertiary icon={l.icon} alt={l.alt} onClick={() => handleRequestInfo(l?.formOptions)} className={className}>{l.label}</Button>
            }

            if (link.startsWith('#')) {
                return <a key={ix} href={link}><Button onClick={callback} tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></a>
            }

            if (link.includes('http')) {
                return <a key={ix} href={link} target="_blank" rel="noopener noreferrer"><Button tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></a>
            }
            if (l.organization && customDomain.app) {
                return <a key={ix} href={`${getAppUrl()}/${organization.link}`}><Button tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></a>
            }
            if (l.root) {
                return <a key={ix} href={`/${organization.link}/${link}`}><Button tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></a>
            }
            if (link.includes(navLinks.pageLink)) {
                return <a key={ix} href={link}><Button onClick={callback} tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></a>
            }
            if (organization && organization.link != 'homegyde' && !customDomain.organization && !standaloneApp && !app) {
                return <Link key={ix} to={`/${organization.link}/${link}`}><Button onClick={callback} tertiary icon={l.icon} alt={l.alt} className={className}>{l.label}</Button></Link>
            }
            return <Link key={ix} to={link}><Button onClick={callback} tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button></Link>
        } else if (callback) {
            return <Button onClick={callback} tertiary alt={l.alt} icon={l.icon} className={className}>{l.label}</Button>
        }
    }


    function getProjects() {
        if (organization?.link == 'homegyde') {
            return config.apps
        }
        const appSet = new Set(organization?.apps)
        const projects = config.apps.filter((x) => appSet.has(x.meta.id))
        return projects
    }

    function handleMedia(x, options = {}) {
        let finalMedia = []
        if (typeof x == 'string') {
            finalMedia = [x]
        } else if (Array.isArray(x)) {
            finalMedia = x
        } else if (x.id) {
            finalMedia = [x]
        } else {
            if (x.mediaTypeId == MediaType.Tour || x.mediaTypeId == MediaType.Spin) {
                finalMedia = [x]
            } else {
                const m = getMediaId(x)
                if (Array.isArray(m)) {
                    finalMedia = m
                } else {
                    finalMedia = [m]
                }
            }
        }
        if (finalMedia.find((x) => !exclusiveMediaPermissions(app, x))) {
            handleExclusive()
        }

        dispatch(showPrompt({ type: PromptType.Lightbox, app: app, organization: organization, media: finalMedia, ...options }))
    }
    function handleSearchValue(field, value) {
        const newSearch = { ...data }
        if (field in newSearch) {
            if (value == 'all') {
                delete newSearch[field]
            } else {
                newSearch[field] = [value]
            }
        } else {
            newSearch[field] = [value]
        }
        setData(newSearch)
    }

    function handleSearch() {
        const finalQuery = { ...data }
        if (FloorplanFilter.Sqft in finalQuery) {
            finalQuery[FloorplanFilter.Sqft] = { min: finalQuery[FloorplanFilter.Sqft], max: 100000 }
        }
        dispatch(resetQuery({ type: QueryType.Projects }))
        dispatch(setQuery({ query: finalQuery, type: QueryType.Projects }))
        dispatch(navigateAsync({ pageType: '', options: { path: { baseRoute: PageType.Developments } } })).catch(logger.error)
    }

    function handleToggleData(x) {
        if (data == x) {
            setData(null)
        } else {
            setData(x)
        }
    }

    function getSearch(x) {
        if (!data) {
            return
        }
        const { label, field } = x
        let items = []
        switch (field) {
            case ProjectFilter.ProjectType: {
                // Aggregate organization app project typse
                const projects = getProjects()
                const allTypes = new Set()
                projects.forEach((x) => {
                    x.meta.projectTypes.forEach((y) => {
                        allTypes.add(y)
                    })
                })
                items.push({ text: 'All', value: 'all' })
                Array.from(allTypes).forEach((x) => {
                    items.push({ text: config.projectTypes[x].name, value: x })
                })
                break
            }
            case FloorplanFilter.Sqft: {
                const projects = getProjects()
                const sqft = [1000, 2000, 3000, 4000, 5000]
                const range = config.filterRanges[FloorplanFilter.Sqft]
                items = [{ text: 'All Sizes', value: 'all' }, ...sqft.filter((x) => x >= range.min && x <= range.max).map((x) => ({ text: `${x}+`, value: x }))]
                break
            }
            case 'neighborhood':
                items = [{ text: 'All', value: 'all' }]
                break
        }
        return <React.Fragment>
            <h5>{x.label}</h5>
            <DropdownMenu buttonClass="no-bg" value={data[field] ? data[field] : 'all'} items={items} onChange={(y) => handleSearchValue(field, y)} />
            {/* <select>
                {data.map((x) => {
                    return <option key={x} value={x.value}>{x.text}</option>
                })}
            </select> */}
        </React.Fragment>
    }

    function submitForm(form, formData) {
        const { finalFormData, promptOptions, finalFormOptions } = packageFormData({ ...formData, tags: form?.tags }, form, footer)

        if (finalFormData.analyticsEventTypeId > 0) {
            dispatch(analyticsEvent(finalFormData.analyticsEventTypeId))
        }
        let exclusiveAccess = false
        return Promise.all([
            dispatch(showPrompt(promptOptions)),
            dispatch(submitBooking({ app, data: finalFormData, options: finalFormOptions })),
        ])
            .then(([a, b]) => {
                try {
                    if (!b.payload.success) {
                        throw b.payload.message
                    } else {
                        if (b.payload.token) {
                            exclusiveAccess = true
                        }
                        return true
                    }
                } catch (e) {
                    throw e
                }
                return false
            }).then((x) => {
                setData(finalFormOptions.successMessage || "Form submitted successfully")
                // Find new exclusive pages
                try {
                    if (exclusiveAccess) {
                        const exclusivePages = app.pages.filter((x) => x.exclusive).sort((a, b) => a.order - b.order)
                        if (exclusivePages && exclusivePages.length > 0) {
                            const pageLink = exclusivePages[0].link
                            setTimeout(() => {
                                window.dispatchEvent(new CustomEvent(CustomEventType.MenuTutorial, {
                                    detail: {
                                        link: pageLink,
                                        title: message.exclusiveUnlock,
                                    }
                                }))
                            }, 100)
                        }
                    }
                } catch (e) {
                    logger.error("Error setting up exclusive pages", e)
                }
                // if (x && redirect && onRedirect) {
                // onRedirect(redirect)
                // }
                // if (x && redirect && onRedirect) {
                // onRedirect(redirect)
                // }
            }).catch((e) => {
                logger.error(e)
                throw new Exception(ErrorMessage.BookingError)
            })
    }

    function handleScrollNext() {
        const nextElem = document.getElementById((idx + 1).toString())
        if (nextElem) {
            const rect = nextElem.getBoundingClientRect()
            try {
                nextElem.parentNode.parentNode.scrollTo({ top: rect.top - 150, behavior: 'smooth' })
            } catch (e) {

            }
            // nextElem.scrollIntoView({ behavior: 'smooth' })
        }
    }

    function handleRequestInfo(x = null) {
        window.dispatchEvent(new CustomEvent(CustomEventType.RequestInfo, { detail: x }))
    }

    function handleExpand() {
        if (finalType == DynamicContentType.RecentBlog) {
            setGridLimit(Math.min(data.length, gridLimit + gridSpan))
        } else if (elements && elements.length > 0) {
            setGridLimit(Math.min(elements.length, gridLimit + gridSpan))
        } else if (mediaIds.length > 0) {
            setGridLimit(Math.min(mediaIds.length, gridLimit + gridSpan))
        }
    }

    function getMapCircle() {
        const API_KEY = getMapsApiKey()
        const location = app.meta.location
        if (!location) {
            return null
        }
        const center = location.latLng.split(',')
        const url = `https://maps.googleapis.com/maps/api/staticmap?markers=${center[0]}%2C${center[1]}&zoom=13&scale=2&size=512x512&maptype=roadmap&format=jpg&key=${API_KEY}`
        const [text, googleUrl] = formatLocation(location)
        return <a href={googleUrl} target="_blank" rel="noopener noreferrer">
            <div className="map-circle">
                <Media fadeIn url={url} />
            </div>
        </a>
    }

    function getProjectDetails(unitConfig) {
        // Helpers
        const formatList = (x, ix) => <React.Fragment>
            {ix > 0 && minimal ? '-' : ' - '}
            {getFractionString(x)}
        </React.Fragment>

        function getFractionString(val: number) {
            if (val % 1 !== 0) {
                return <React.Fragment>{Math.floor(val)} <span className="fraction"><sup>1</sup>/<sub>2</sub></span></React.Fragment>
            }
            return <React.Fragment> {val.toString()} </React.Fragment>
        }
        const formatMinMax = (x, unit = '', suffix = '') => {
            const min = Math.min.apply(null, x)
            const max = Math.max.apply(null, x)
            if (min == max) {
                return <span className="minmax">{unit}{getFractionString(min)} {suffix}</span>
            }
            return <span className="minmax">{unit}{getFractionString(min)}{' - '}{unit}{getFractionString(max)} {suffix}</span>
        }


        function getUnitString() {
            let unitString = app.meta.stats.units > 0 ? `${app.meta.stats.units} Units` : ''
            if (app.meta.stats.phases > 0) {
                unitString += ` x ${app.meta.stats.phases} Phases`
            }
            if (app.meta.stats.floors > 0) {
                unitString += ` | ${app.meta.stats.floors} Storeys`
            }
            return unitString
        }

        const projectDetails = {
            types: [],
            sqft: [],
            units: [],
            beds: [],
            baths: [],
            ...unitConfig,
        }

        const addBasicStats = (stats) => {
            const fields = [['sqft', 'sf'], ['beds', ''], ['baths', '']]
            fields.forEach((x) => {
                const [key, suffix] = x
                if (stats[key]) {
                    projectDetails[key].push(formatMinMax(stats[key], '', suffix))
                }
            })
        }
        if (projectDetails.statsByBuildingType) {
            let stats = null
            if (projectDetails.phase) {
                const phase = app.phases.find((x) => x.name.includes(projectDetails.phase.toString()))
                if (phase && phase.id in app.meta.stats.phase) {

                    stats = app.meta.stats.phase[phase.id].buildingType
                }
            } else {
                stats = app.meta.stats.buildingType
            }
            app.buildingTypes.forEach((x) => {
                const stats = app.meta.stats.buildingType[x.id]
                projectDetails.types.push(app.maps.buildingType[x.id].name)
                addBasicStats(stats)
                if (stats.units) {
                    projectDetails.units.push(stats.units)
                }
            })
        } else {
            addBasicStats(app.meta.stats)
        }

        if (app.meta.stats.units && app.meta.stats.units > 0 && projectDetails.units.length == 0) {
            projectDetails.units.push(getUnitString())
        }

        if (screen.isMobile && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait) {
            return <React.Fragment>
                {projectDetails.types && projectDetails.types.length > 0 && <div className="row detail-tabs">
                    <div className="row">
                        {projectDetails.types.map((x, ix) => {
                            return <Button key={ix} className={ix == data ? 'selected' : ''} onClick={() => setData(ix)}>{x}</Button>
                        })}
                    </div>
                </div>}

                <div className="row detail-row fadeIn" key={`unit-${data}`}>
                    <h5>NUMBER OF UNITS</h5>
                    {projectDetails.units.length > 0 && data < projectDetails.units.length && <span>{projectDetails.units[data]}</span>}
                    {projectDetails.units.length == 0 && <span>Not Set</span>}
                </div>
                <div className="row detail-row fadeIn" key={`sqft-${data}`}>
                    <h5>SQUARE FOOTAGE</h5>
                    {projectDetails.sqft.length > 0 && data < projectDetails.sqft.length && <span>{projectDetails.sqft[data]}</span>}
                    {projectDetails.sqft.length == 0 && <span>Not Set</span>}
                </div>
                <div className="row detail-row fadeIn" key={`bed-${data}`}>
                    <h5>BEDROOMS</h5>
                    {projectDetails.beds.length > 0 && data < projectDetails.beds.length && <span>{projectDetails.beds[data]}</span>}
                    {projectDetails.beds.length == 0 && <span>Not Set</span>}
                </div>
                <div className="row detail-row fadeIn" key={`baths-${data}`}>
                    <h5>BATHROOMS</h5>
                    {projectDetails.baths.length > 0 && data < projectDetails.baths.length && <span>{projectDetails.baths[data]}</span>}
                    {projectDetails.baths.length == 0 && <span>Not Set</span>}
                </div>
            </React.Fragment>
        } else {
            const rowCount = Math.max(projectDetails.units.length, projectDetails.sqft.length, projectDetails.beds.length, projectDetails.baths.length)
            const rows = [...Array(rowCount).keys()]

            return <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            {projectDetails.types && projectDetails.types.length > 0 && <th><h5>HOME TYPE</h5></th>}
                            <th><h5>NUMBER OF UNITS</h5></th>
                            <th><h5>SQUARE FOOTAGE</h5></th>
                            <th><h5>BEDROOMS</h5></th>
                            <th><h5>BATHROOMS</h5></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((ix) => {
                            return <tr key={ix}>
                                {projectDetails.types && ix < projectDetails.types.length && <td>
                                    <span>{projectDetails.types[ix]}</span>
                                </td>}
                                <td>
                                    {ix < projectDetails.units.length ? <span>{projectDetails.units[ix]}</span> : <span>Not Set</span>}
                                </td>
                                <td>
                                    {ix < projectDetails.sqft.length ? <span>{projectDetails.sqft[ix]}</span> : <span>Not Set</span>}
                                </td>

                                <td>
                                    {ix < projectDetails.beds.length ? <span>{projectDetails.beds[ix]}</span> : <span>Not Set</span>}
                                </td>
                                <td>
                                    {ix < projectDetails.baths.length ? <span>{projectDetails.baths[ix]}</span> : <span>Not Set</span>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        }
    }

    function getContent() {
        if (!initialized) {
            return null
        }

        let forceShow = editInline
        let alignmentString = alignment !== null ? (alignment == 0 ? 'left' : alignment == 1 ? 'center' : 'right') : ''
        let rowClass = `dynamic-content row${focus ? ' focus' : ''}${alt ? ' alt' : ''}${inline ? ' inline' : ''}${dark ? ' dark' : ''}${alignment ? ` align-${alignmentString}` : ''}${full ? ' full' : ''}${expandable && gridLimit == -1 ? ' collapsed' : ''}${expandable ? ' expandable' : ''}${underline == false ? ' no-underline' : ''}${spacing != null ? ` spacing-${spacing}` : ''}${editing ? ' editing' : ''}${media && media.length > 0 && media[0].position != null ? ` media-position-${media[0].position}` : ''}`

        if (dynamicContentTypeId != DynamicContentType.Grid && size == 1) {
            rowClass = `${rowClass} large`
        }

        const rowStyle = {}
        const delayClass = screen.isMobile ? '' : ' animate__delay-01s'
        const delayRate = screen.isMobile ? 0.025 : 0.1

        if (backgroundColor != null) {
            rowStyle.backgroundColor = `#${backgroundColor}`
        }

        const headerAnimation = animated ? 'animate__animated animate__fadeIn animate__cubic' : ''
        const mediaAnimation = animated ? 'animate__animated animate__fadeInUpSmall animate__cubic' : ''
        const titleAnimation = animated ? 'animate__animated animate__fadeInUpSmall animate__cubic' : ''
        const bodyAnimation = animated ? `animate__animated animate__fadeIn${delayClass}` : ''
        const elementAnimation = animated ? 'animate__animated animate__fadeInUpSmall animate__cubic' : ''
        const elementStyle = (x, ix) => animated ? { WebkitAnimationDelay: `${ix * delayRate}s`, animationDelay: `${ix * delayRate}s` } : {}
        let collapseElem = null
        if (expandable && (gridLimit == -1 || (gridLimit >= 0 && gridLimit != gridItems))) {
            const expandLink = links?.length > 0 && links[0].link == 'expand' ? links[0] : null
            const regularLink = links?.length > 0 && links[0].link != 'expand'
            collapseElem = <div className="custom-collapse animate__animated animate__fadeInUpSmall animate__delay-1s">
                {regularLink && !expandLink && getLink(links[0], 0, "expand-button")}
                {!regularLink && expandLink && <Button tertiary onClick={handleExpand} className="expand-button">
                    {expandLink.label || 'More'}
                </Button>}
                {!regularLink && !expandLink && <Button tertiary onClick={handleExpand} className="expand-button">
                    {'See More'}
                </Button>}
            </div>
        }

        let key = `${idx}-${props.id}-${finalType}`//-${additionalSource?.refresh}`

        let contentElem = null

        switch (finalType) {
            case DynamicContentType.Blurb: {
                const mainMedia = media[0]
                const mainType = getMediaType(mainMedia)
                let debug = false

                let mediaAttrs = ""
                if (mainMedia && mainMedia.alignment) {
                    mediaAttrs = `align-${mainMedia.alignment}`
                }
                if (mainMedia && mainMedia.orientation) {
                    mediaAttrs += ` orientation-${mainMedia.orientation}`
                }
                let mediaElems = media.map((x, ix) => {
                    if (!x) {
                        return
                    }
                    const finalPoints = x?.points || points
                    const type = allMedia[x.mediaId]?.mediaTypeId
                    let size = media.length > 1 ? 6 : (x.size ? x.size : 4)
                    const mediaClass = `column dynamic-content-media col-${size}${(x.clickable || type > MediaType.Image) ? ' clickable' : ''} ${mediaAnimation} ${mediaAttrs}`
                    return <div className={mediaClass} data-type={type} data-idx={ix}>
                        <MediaIcon type={type} />
                        {getMedia(x)}
                        {/* {getMedia(x, '', (x.clickable || type != null) ? () => handleMedia({ ...x, mediaTypeId: type }) : null, x.size == 8 ? ThumbSize.ExtraLarge : ThumbSize.Large)} */}
                        {ix == 0 && finalPoints?.length > 0 && <div className="dynamic-content-media-points">
                            {finalPoints.map((x) => {
                                let size = 30 * (x.size ? x.size : 1)
                                let pointStyle = {
                                    left: `calc(${x.x}% - ${size}px * 0.5)`,
                                    top: `calc(${x.y}% - ${size}px * 0.5`,
                                    width: `${size}px`,
                                    height: `${size}px`
                                }
                                return <div className="content-point media-point animate__animated animate__bounceIn animate__delay-2s" style={pointStyle}>
                                    {x.label && <span>{x.label}</span>}
                                </div>
                            })}
                        </div>}
                        {x.subtitle && <h4>{x.subtitle}</h4>}
                    </div>
                })
                if (mediaElems.length > 1) {
                    mediaElems = <div className={`media-wrapper multi column col-${media[0].size || 8}`}>
                        {mediaElems}
                    </div>
                }
                contentElem = <React.Fragment>
                    <div className="column">
                        {(title || subtitle || supertitle || titleMedia) && <div className={`row content-title${titleMedia ? ' with-media' : ''}`}>
                            {supertitle && <h4>{supertitle}</h4>}
                            {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                            {subtitle && <h4>{subtitle}</h4>}
                            {titleMedia && getMedia(titleMedia)}
                        </div>}
                        {mainMedia?.position == 'top' && mediaElems}
                        {header && <span className="header"><i>{header}</i></span>}
                        {/* {body && <span className={`body ${bodyAnimation}`}><ReactMarkdown remarkPlugins={[gfm]} renderers={{ Link: MarkdownLink }} linkTarget="_blank">{body}</ReactMarkdownNewline> </span>} */}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdown remarkPlugins={[gfm]}

                            components={{
                                a: (props) => {
                                    const { href, children } = props
                                    if (!href?.includes('www') && !href?.includes('https') && !href?.includes('.com')) {
                                        return getLink({ link: href, label: children[0] })
                                    } else {
                                        return <a href={href} target="_blank">{children}</a>
                                    }
                                }
                            }}>{body}</ReactMarkdown> </span>}
                        {links && links.length > 0 && <div className="dynamic-content-links row">
                            {links.map(getLink)}
                        </div>}
                        {footer && <h4 className="content-footer">{footer}</h4>}
                    </div>
                    {mainMedia?.position != 'top' && mediaElems}
                </React.Fragment>
                break
            }
            case DynamicContentType.Accordian: {
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h2>{title}</h2>}
                        {subtitle && <h4>{subtitle}</h4>}
                        {header && <span className="header"><i>{header}</i></span>}
                        {body && <span className="body"><ReactMarkdownNewline>{body}</ReactMarkdownNewline> </span>}
                        {elements && elements.map((x, ix) => <CollapsibleRow key={ix} index={ix + 1} title={x.title}>
                            <span className="fadeIn"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>
                        </CollapsibleRow>)}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Features: {
                contentElem = <React.Fragment>
                    {title && <h2>{title}</h2>}
                    <div className="row">
                        {elements.map((x, ix) => <div key={ix} className={`column col-4 ${elementAnimation}`} style={elementStyle(x, ix)}>
                            {x.media && getMedia(x.media)}
                            {x.title && <h2><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h2>}
                            {x.subtitle && <h4>{x.subtitle}</h4>}
                            {x.header && <span className="header"><i>{x.header}</i></span>}
                            {x.body && <span className="body"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline> </span>}
                            {x.links && <div className="dynamic-content-links row">
                                {x.links.map(getLink)}
                            </div>}
                        </div>)}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Header: {
                forceShow = true
                const format = 0
                rowClass = `${rowClass} content-slideshow ${headerAnimation}`
                if (media.length > 0) {
                    if (media[0].mediaId == null && media[[0]].galleryId != null) {
                        const gallery = (app ? app.galleries : organization.galleries).find((x) => x.id == media[0].galleryId)
                        if (gallery) {
                            if (contentStyle == 0 || true) {
                                contentElem = <ContentGallery app={app} link={gallery.link} format={format} />
                            } else if (contentStyle == 1) {
                                const mediaIds = gallery.media.map((x) => x.mediaId)
                                if (mediaIds.length == 1) {
                                    contentElem = <div className="slideshow-wrapper">
                                        {title && <h1>{title}</h1>}
                                        <Media app={app} organization={!app ? organization : null} mediaId={mediaIds[0]} fadeIn />
                                    </div>
                                } else {
                                    contentElem = <SlideShow app={app} organization={!app ? organization : null} media={mediaIds} zoomable={false} autoScroll={8000} showIndex={false} />
                                }
                            }
                        } else {
                            logger.error("Missing gallery", media[0].galleryId, app.galleries)
                        }
                    } else if (media[0].mediaId != null) {

                        contentElem = <div className="slideshow-wrapper">
                            {title && <h1>{title}</h1>}
                            <Media app={app} organization={!app ? organization : null} mediaId={media[0].mediaId} fadeIn />
                        </div>
                    }
                }
                break
            }
            case DynamicContentType.Event: {
                dividerAfter = true
                const launchDate = date ? fnc.dateFriendly(date, { dayOfTheWeek: true }) : null
                let mediaId = null
                let m = null
                let mediaHeight = 0
                let mediaWidth = 0
                let mediaOptions = {}
                if (media && media.length > 0 && media[0] == 'organization') {
                    mediaId = organization.logoMediaId
                    m = allMedia[mediaId]
                    mediaOptions = { organization }
                } else if (app && app.meta) {
                    if (builder) {
                        mediaId = builder.logoMediaId
                        m = allMedia[mediaId]
                        mediaOptions = { builder }
                    }
                } else if (media && media.length > 0) {
                    // Find gallery
                    const gallery = (app ? app.galleries : organization.galleries).find((x) => x.link == media[0].galleryLink)
                    if (gallery) {
                        mediaId = gallery.media[media[0].index]?.mediaId
                        m = allMedia[mediaId]
                        if (app) {
                            mediaOptions = { app }
                        } else if (organization) {
                            mediaOptions = { organization }
                        }
                    }
                }
                if (m) {
                    mediaHeight = 140
                    mediaWidth = mediaHeight * m.width / m.height
                }
                let mediaAttrs = ''
                let mediaType = ''
                if (media && media.length > 0) {
                    if (media[0]?.alignment) {
                        mediaAttrs = `align-${media[0].alignment}`
                    }
                    if (media[0]?.orientation) {
                        mediaAttrs = `${mediaAttrs} orientation-${media[0].orientation}`
                    }
                    mediaType = getMediaType(media[0])
                }
                const longTitle = title?.length > 30
                contentElem = <React.Fragment>
                    <div className="column">
                        {(title || subtitle || launchDate || m) && <div className={`row content-header ${headerAnimation}`}>
                            {m && <div className="column col-shrink content-builder" style={{ width: `${mediaWidth}px`, height: `${mediaHeight}px` }}>
                                {mediaId && <Media mediaId={mediaId} {...mediaOptions} fadeIn />}
                            </div>}
                            {m && <div className="column divider" />}
                            <div className="column content-title">
                                {title && !longTitle && <h1>{title}</h1>}
                                {title && longTitle && <h2>{title}</h2>}
                                {subtitle && <h4><ReactMarkdownNewline>{subtitle}</ReactMarkdownNewline></h4>}
                                {launchDate && <span>{launchDate}</span>}
                            </div>
                        </div>}
                        {media && media.length > 0 && <div className={`row dynamic-content-media ${mediaAnimation} ${mediaAttrs}`} data-type={mediaType} data-position="top">
                            {getMedia(media[0])}
                        </div>}
                        {body && <React.Fragment>
                            <div className={`row content-divider ${bodyAnimation}`} />
                            <div className={`row content-details ${bodyAnimation}`}>
                                <span className="span">
                                    <ReactMarkdownNewline>
                                        {body}
                                    </ReactMarkdownNewline>
                                </span>
                            </div>
                        </React.Fragment>}
                        <div className={`row content-details ${bodyAnimation}`}>
                            {elements && elements.map((x, ix) => {
                                const location = x.locations?.length > 0 ? app.locations.find((y) => y.id == x.locations[0].locationId) : null
                                const col = elements.length < 3 ? 'col-4' : 'col-3'
                                return <div className={`column ${col}`} key={ix}>
                                    {x.title && <h5>{x.title.replace(/\\n/g, '\n')}</h5>}
                                    {location && <React.Fragment>
                                        <h3><ReactMarkdownNewline>{location.name.replace(/\\n/g, '\n')}</ReactMarkdownNewline></h3>
                                        <LocationLink location={location} />
                                    </React.Fragment>}
                                    {x.body && <span><ReactMarkdown remarkPlugins={[gfm]} children={x.body} /></span>}
                                </div>
                            })}
                        </div>
                        {links && <div className={`row content-links ${bodyAnimation}`}>
                            {links.map(getLink)}
                        </div>}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Form: {
                if (!forms || forms.length == 0) {
                    return <div className="column">
                        <h3>No forms added</h3>
                    </div>
                }
                const { submitMessage, tags, fields } = forms[0]
                contentElem = <React.Fragment>
                    <div className={`column ${bodyAnimation}`}>
                        <div className="row content-details">

                            <div className="column">
                                {title && <h3><ReactMarkdown>{title}</ReactMarkdown></h3>}
                                {subtitle && !data && <h5>{subtitle}</h5>}
                                {location && <div className="row content-details" style={{ marginBottom: '50px', marginTop: '30px' }}>
                                    <div className="column col-4">
                                        <h5>LOCATION:</h5>
                                        <h3><React.Fragment>{location.name}</React.Fragment></h3>
                                        <LocationLink location={location} />
                                    </div>
                                    <div className="column col-4">
                                        <h5>TIME:</h5>
                                        <h3>{time}</h3>
                                    </div>
                                </div>}
                                {body && !data && <span className="body"><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                            </div>
                        </div>
                        {!data && <div className="row">
                            {forms[0].hubspotUseEmbedded && <HubspotForm app={app} formId={forms[0].hubspotFormId} onSubmit={(x) => submitForm(forms[0], x)} />}
                            {!forms[0].hubspotUseEmbedded && <ContentForm fields={fields} onSubmit={(x) => submitForm(forms[0], x)} maxColumns={2} preventNav={forms[0].preventNavigation} {...forms[0]}>
                                {(handleSubmit, loaded) => {
                                    return <div className="row submit-row">
                                        <Button id="register-submit" className={!loaded ? 'disabled' : ''} tertiary icon="far fa-paper-plane" onClick={handleSubmit}>{submitMessage || 'Register'}</Button>
                                    </div>
                                }}
                            </ContentForm>}
                        </div>}
                        {data && <span className="body"><ReactMarkdownNewline>{data}</ReactMarkdownNewline></span>}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Splash: {
                // let gallery = null
                // if (media) {
                //     if (app) {
                //         gallery = app.galleries.find((x) => x.link == media.galleryLink)
                //     } else if (builder) {
                //         gallery = builder.galleries.find((x) => x.link == media.galleryLink)
                //     } else if (organization) {
                //         gallery = organization.galleries.find((x) => x.link == media.galleryLink)
                //     }
                // }
                rowStyle.height = 'calc(100vh - var(--topbar-height))'
                // style.minHeight = style.height
                contentElem = <React.Fragment>
                    {getMedia(media, '', null, null, true)}
                    {/* {gallery && <SlideShow app={app} organization={organization} zoomable={false} media={gallery.media.map((x) => x.mediaId)} showIndex={false} showArrows={!mobilePortrait} showDots={mobilePortrait} alt />} */}
                    <div className="column">
                        {supertitle && <h5><ReactMarkdownNewline>{supertitle}</ReactMarkdownNewline></h5>}
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {subtitle && <h4>{subtitle}</h4>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                        {links && <div className="row dynamic-content-links">
                            {links.map(getLink)}
                        </div>}
                    </div>
                    {elements?.length > 0 && <div className="column request-column">
                        {elements.map((x, ix) => {
                            if (x.dynamicContentTypeId == DynamicContentType.RequestInfo) {
                                const form = x.forms?.length > 0 ? x.forms[0] : null
                                return <RequestInfo key={ix} app={app} organization={organization} forceOpen={true} title={x.title} body={x.body} data={x.data} {...form} />
                            }
                            return null
                        })}
                    </div>}
                    {/* <IconButton noBg className="next-page" icon="fas fa-chevron-down" onClick={handleScrollNext} /> */}
                </React.Fragment>
                break
            }
            case DynamicContentType.Latest: {
                const projects = getProjects()
                const rowCount = 4
                const projectArr = [...Array(rowCount).keys()]
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        <div className="row content-details">
                            <div className="column">
                                {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                            </div>
                            <div className="column" style={{ width: 'auto', justifyContent: 'flex-end' }}>
                                <div className="row dynamic-content-links">
                                    {links && links.map(getLink)}
                                </div>
                            </div>
                        </div>
                        {projects && <div className="row tile-list custom-tile-list">
                            {projectArr.map((x) => {
                                if (x < projects.length) {
                                    return <ProjectTile key={x} app={projects[x]} minimal />
                                }
                                return <ProjectTile key={x} app={null} minimal />
                            })}
                        </div>}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Events: {
                const rowCount = 4
                // return <div id={props.link ? props.link : idx} data-id={id} className={rowClass} data-type="events" data-inviewport>

                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        <div className="row content-details">
                            <div className="column">
                                {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                            </div>
                            <div className="column" style={{ width: 'auto', justifyContent: 'flex-end' }}>
                                <div className="row dynamic-content-links">
                                    {links && links.map(getLink)}
                                </div>
                            </div>
                        </div>
                        {elements && <div className="row tile-list custom-tile-list">
                            {elements.map((x) => {
                                const nav = () => {
                                    dispatch(navigate(x.link))
                                }
                                return <Tile key={x.title} className="project-tile" style={{ cursor: 'default' }}>
                                    <div className="project-tile-title">
                                        <div className="column">
                                            <h4>{x.title}</h4>
                                            {x.date && <div className="row location-wrapper">
                                                <Icon noBg icon="fas fa-calendar-day" />
                                                <span><ReactMarkdownNewline>{x.date.toString()}</ReactMarkdownNewline></span>
                                            </div>}
                                            {x.location && <div className="row location-wrapper">
                                                <Icon noBg icon="fas fa-map-marker-alt" />
                                                <LocationLink location={x.location} minimal={true} />
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="project-tile-preview">
                                        {x.media && getMedia(x.media)}
                                    </div>
                                    <div className="row project-details">
                                        <div className="project-detail">
                                            <span><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>
                                        </div>
                                    </div>
                                    <div className="row project-tile-options">
                                        {x.links && x.links.map(getLink)}
                                    </div>
                                </Tile>
                            })}
                        </div>}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Search: {
                const searchElems = [
                    {
                        label: 'Home Type',
                        field: ProjectFilter.ProjectType,
                    },
                    {
                        label: 'Square Feet',
                        field: FloorplanFilter.Sqft,
                    },
                ]
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                        <div className="row select-row">
                            {searchElems.map((x) => <div className="column test" key={x.value}>
                                {getSearch(x)}
                            </div>)}
                            <div className="column">
                                <Button tertiary onClick={handleSearch}>Search Now</Button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Numbered: {
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {subtitle && <h4>{subtitle}</h4>}
                        {header && <span className="header"><i>{header}</i></span>}
                        {body && <span className="body"><ReactMarkdownNewline>{body}</ReactMarkdownNewline> </span>}
                        <div className="row number-row">
                            {elements.map((x, ix) => {
                                return <div className={`column col-4 ${elementAnimation}`} key={ix} style={elementStyle(x, ix)}>
                                    <div className="number-media-wrapper">
                                        {x.media && getMedia(x.media)}
                                        <h1>{ix + 1}</h1>
                                    </div>
                                    <div className="number-content-wrapper">
                                        {x.title && <h4><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h4>}
                                        {x.body && <span className="body"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                        <div className="row dynamic-content-links">
                                            {x.links && x.links.map(getLink)}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </React.Fragment>
                break
            }

            case DynamicContentType.Developers: {
                // Aggregate builders for this org
                const projects = getProjects()
                const builderMap = fnc.objIdMap(config.builders)
                const builders = Object.values(projects.reduce((acc, x) => {
                    const builder = builderMap[x.meta.builderId]
                    if (builder && !(builder.id in acc)) {
                        return { ...acc, [builder.id]: builder }
                    }
                    return acc
                }, {}))
                const rowCount = 5
                const builderArr = [...Array(rowCount).keys()]
                const pages = Math.ceil(builders.length / rowCount)

                function handleNext() {
                    let newData = (data != null ? data : 0)
                    if (newData < pages - 1) {
                        newData += 1
                    } else {
                        newData = 0
                    }
                    setData(newData)
                }

                function handlePrev() {
                    let newData = (data != null ? data : 0)
                    if (newData > 0) {
                        newData -= 1
                    } else {
                        newData = pages - 1
                    }
                    setData(newData)
                }
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {subtitle && <span className={`subtitle ${bodyAnimation}`}><ReactMarkdownNewline>{subtitle}</ReactMarkdownNewline></span>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                        <div className={`row developer-row${pages > 1 ? ' multi-page' : ''}`}>
                            {builderArr.map((x, ix) => {
                                const fixedIndex = (data || 0) * rowCount + x
                                if (fixedIndex < builders.length) {
                                    return <div className={`column ${elementAnimation}`} key={fixedIndex} style={elementStyle(x, ix)}>
                                        <a href={builders[fixedIndex].site} target="_blank" rel="noopener noreferrer">
                                            <Media mediaId={builders[fixedIndex].logoMediaId} builder={builders[fixedIndex]} thumb thumbSize={ThumbSize.Large} />
                                        </a>
                                    </div>
                                }
                                return <div className={`column ${elementAnimation}`} key={fixedIndex} style={elementStyle(x, ix)}>
                                    <div className="placeholder" />
                                </div>
                            })}
                            {pages > 1 && <React.Fragment>
                                <IconButton className="next" icon="fas fa-chevron-right" onClick={handleNext} />
                                <IconButton className="prev" icon="fas fa-chevron-left" onClick={handlePrev} />
                            </React.Fragment>}
                        </div>
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Map:
                if (app && app.meta && alt) {
                    const builder = config.builders.find((x) => x.id == app?.meta?.builderId)
                    contentElem = <React.Fragment>
                        <div className="column">
                            <div className="row content-info">
                                <div className="column">
                                    <div className="row content-info-title">
                                        <h4>Site Location</h4>
                                    </div>
                                    <ProjectMap apps={[app]} focusIds={[app.meta.id]} id={splitIndex != null ? `${app.meta.id}-${splitIndex}` : null} remember={false} scrollable={false} />
                                </div>
                            </div>
                            <div className=" row content-info">
                                {app.meta.location && <div className="column col-4 content-info-location">
                                    <h5>SITE LOCATION</h5>
                                    <Icon noBg icon="fas fa-map-marker-alt" tertiary />
                                    <LocationLink location={app.meta.location} altB />
                                </div>}
                                {app.meta.officeLocation && <div className="column col-4 content-info-location">
                                    <h5>OFFICE LOCATION</h5>
                                    <Icon noBg icon="fas fa-map-marker-alt" tertiary />
                                    <LocationLink location={app.meta.officeLocation} altB />
                                </div>}
                                {builder && <div className="column col-4 content-info-builder">
                                    <a href={builder.site}><Media builder={builder} mediaId={builder.logoMediaId} /></a>
                                    <a href={builder.site}><span>{builder.site.replace('https://', '')}</span></a>
                                </div>}
                            </div>
                        </div>
                    </React.Fragment>
                } else {
                    const projects = getProjects()
                    function handleSelect(x) {
                        dispatch(navigateAsync({ app: x }))
                    }
                    contentElem = <ProjectMap apps={projects} focusIds={data ? [data.meta.id] : []} onProjectFocus={setData} onProjectSelect={handleSelect} scrollable={false} />
                }
                break
            case DynamicContentType.RequestInfo:
                return null
                return <RequestInfo app={app} organization={organization} data={props.data} />

            case DynamicContentType.Summary:
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h2 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h2>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                        {links && <div className="row dynamic-content-links">
                            {links.map(getLink)}
                        </div>}
                    </div>
                </React.Fragment>
                break
            case DynamicContentType.Tabbed:
                if (mobilePortrait) {
                    contentElem = <React.Fragment>
                        <div className="column">
                            {elements.map((x, ix) => {
                                return <React.Fragment>
                                    <h3><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h3>
                                    {x.body && <span className="body"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                    {x.media && <div className="row media-wrapper">{getMedia(x.media)}</div>}
                                </React.Fragment>
                            })}
                        </div>
                    </React.Fragment>
                } else if (elements) {

                    const elem = elements[data != null ? data : 0]
                    contentElem = <React.Fragment>
                        <div className="column">
                            <div className="row custom-tabs">
                                {elements.map((x, ix) => <div className={`column ${ix == data || (data === null && ix == 0) ? ' selected' : ''}`} onClick={() => {
                                    setData(ix)
                                }}>
                                    <h3><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h3>
                                </div>)}
                            </div>
                            {elem && <div className={`row ${titleAnimation}`} key={data}>
                                <div className="column">
                                    {elem.body && <span className="body"><ReactMarkdownNewline>{elem.body}</ReactMarkdownNewline></span>}
                                    {elem.media && <div className="row media-wrapper">{getMedia(elem.media)}</div>}
                                </div>
                            </div>}
                        </div>
                    </React.Fragment>
                }
                break
            case DynamicContentType.Grid:
                let finalSplit = !size ? [4, 8] : [6, 6]
                let colA = finalSplit && finalSplit.length > 0 ? finalSplit[0] : 4
                let colB = finalSplit && finalSplit.length > 1 ? finalSplit[1] : 8
                rowClass = `${rowClass} grid-${gridSize}`
                const finalIds = Array.isArray(mediaIds) ? mediaIds : (mediaIds ? [mediaIds] : null)
                // return <div id={idx} data-id={id} className={`${rowClass} grid-${size != null ? size : '2'}`} data-type="grid" data-inviewport={animated && !mobilePortrait}>
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {subtitle && <span className={`subtitle ${bodyAnimation}`}><ReactMarkdownNewline>{subtitle}</ReactMarkdownNewline></span>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                        {links && links.length > 0 && <div className="dynamic-content-links row">
                            {links.map(getLink)}
                        </div>}
                        <div className="row grid-row">
                            {finalIds?.map((x, ix) => {
                                if (gridLimit >= 0 && ix >= gridLimit) {
                                    return null
                                }
                                const mainMedia = getTypeMedia(allMedia[x])
                                const mainType = mainMedia.mediaTypeId
                                return <div className={`column grid-cell dynamic-content-media ${elementAnimation}`} style={elementStyle(x, ix)} key={ix}>
                                    <MediaIcon type={mainType} />
                                    {getMedia([{ clickable: true, mediaId: mainMedia.mediaId || mainMedia.id }], null, (mediaId) => {
                                        if (mainType == MediaType.Image || mainType == MediaType.Video) {
                                            handleMedia(mediaIds, { startIndex: ix })
                                        } else if (mainType == MediaType.Tour) {
                                            // TODO, this is a hack
                                            const modelhome = app.modelhomes.find(x => x.mediaId == mainMedia.id)
                                            if (modelhome) {
                                                handleMedia({ type: mainType, link: modelhome.link })
                                            }
                                        }
                                    })}
                                </div>
                            })}
                            {elements?.map((x, ix) => {
                                if (gridLimit >= 0 && ix >= gridLimit) {
                                    return null
                                }
                                let finalColA = colA
                                let finalColB = colB
                                if (x.media?.length > 0 && x.media[0]?.size) {
                                    finalColA = x.media[0].size
                                    finalColB = 12 - x.media[0].size
                                }
                                return <div className={`column col-6 grid-cell ${elementAnimation}`} style={elementStyle(x, ix)} key={ix}>
                                    <div className="row">
                                        <div className={`column col-${finalColA}`}>
                                            {x.media && getMedia(x.media, null, handleMedia)}
                                        </div>
                                        <div className={`column col-${finalColB}`}>
                                            {x.title && <h3><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h3>}
                                            {x.subtitle && <h5><ReactMarkdownNewline>{x.subtitle}</ReactMarkdownNewline></h5>}
                                            {x.body && <span className="body"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </React.Fragment>
                break
            case DynamicContentType.Neighbourhood:
                const mapStyles = organizationMapStyles(organization)

                // Fit to scrollable region height
                if (rowRef) {
                    const bounds = rowRef.parentNode.parentNode.getBoundingClientRect()
                    rowStyle.height = `calc(${bounds.height}px - 100px)`
                    rowStyle.maxHeight = `calc(${bounds.height}px - 100px)`
                }
                const locationMap = fnc.objIdMap((app ? app : organization)?.locations)

                let mapId = title.toAlphanum()
                contentElem = <Map
                    key={additionalSource.refresh}
                    id={mapId}
                    title={title}
                    body={body}
                    app={app}
                    organization={organization}
                    kml={dataC}
                    elements={!dataC ? elements : null}
                    locationMap={locationMap}
                    focusIds={dataB ? [dataB] : []}
                    defaultMarker={media?.default}
                    styles={mapStyles}
                    defaultZoom={10}
                    scrollable={standalone}
                    remember={false}
                    moveToFocus={true}
                    generate={user?.admin}
                    sidebar={true}
                    options={{ mapTypeControl: false, streetViewControl: false }}
                    onReset={() => setDataB(media?.default)}
                    onFocus={setDataB}
                    elementMap={(x, place) => {
                        let locationElem = null
                        let finalLocation = null
                        if (place && place.formattedAddress) {
                            locationElem = <a href={place.url} target="_blank" rel="noopener noreferrer" className="contact-info"><span>{place.formattedAddress}</span></a>
                        } else if (x.location) {
                            finalLocation = x.location
                        } else if (x.locations && x.locations.length > 0) {
                            finalLocation = x.locations[0]
                        }
                        if (finalLocation && finalLocation.latLng) {
                            locationElem = <LocationLink location={finalLocation} minimal />
                        }
                        let mediaUrl = null
                        if (place && place.photos && place.photos.length > 0) {
                            const m = allMedia[place.photos[0]]
                            if (m) {
                                mediaUrl = getMediaLink(place.photos[0], { app, builder, organization })
                            }
                        }
                        return ReactDOMServer.renderToString(<div className="app-popup">
                            {mediaUrl && <div className="project-tile-preview">
                                <img src={mediaUrl} />
                            </div>}

                            <div className="project-tile-details">
                                <div className="column">
                                    <h5>{x.title}</h5>
                                    {locationElem && <div className="row location-wrapper">
                                        <Icon noBg icon="fas fa-map-marker-alt" />
                                        {locationElem}
                                    </div>}
                                    {/* <a href={`#${x.meta.link}`} className="project-tile-browse"><span className="row">Browse Community<Icon noBg icon="fas fa-chevron-right" /></span></a> */}
                                    {place && place.website && <a className="project-tile-browse" href={place.website} target="_blank" rel="noopener noreferrer"><span className="row">Visit Website<Icon noBg icon="fas fa-chevron-right" /></span></a>}
                                </div>
                            </div>
                        </div>)
                    }} />

                // if (media && media?.link.includes('google.com')) {
                //     return <div id={idx} className={`${rowClass} with-iframe`} data-type="neighbourhood">
                //         {getEditOptions()}
                //         <div className="column">
                //             {title && <h3><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                //             {body && <span className="body"><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                //             <div className="row iframe-wrapper">
                //                 <iframe src={media.link} />
                //             </div>
                //         </div>
                //     </div>
                // }

                // let index = 0

                // const handleGroup = (e) => {
                //     setData(e)
                //     setDataB(null)
                // }


                // const handleMouseOver = (e, x) => {
                //     if (e) {
                //         e.stopPropagation()
                //     }
                //     window.dispatchEvent(new CustomEvent(CustomEventType.ShowMapPopup, { detail: { id: x } }))
                // }

                // const handleMouseLeave = (e, x) => {
                //     if (e) {
                //         e.stopPropagation()
                //     }
                //     setTimeout(() => {
                //         window.dispatchEvent(new CustomEvent(CustomEventType.HideMapPopup, { detail: { id: x } }))
                //     }, 10)
                // }

                // // Gather markers
                // const markers = []
                // const finalElements = dataC && dataC.elements ? dataC.elements : elements
                // const finalTitle = dataC && dataC.title ? dataC.title : title
                // const finalBody = dataC && dataC.body ? dataC.body : body
                // finalElements?.forEach((x, ix) => {
                //     if (data != null && ix != data) {
                //         index += x.elements.length
                //         return
                //     }
                //     x.elements.forEach((y) => {
                //         index += 1
                //         const [lat, lng] = y.location.latLng ? y.location.latLng.split(',').map((x) => parseFloat(x.trim())) : [0, 0]
                //         const style = dataC && dataC.icons ? dataC.icons[x.style] : {}
                //         markers.push({
                //             id: index,
                //             type: MarkerType.Circle,
                //             position: { lat, lng },
                //             value: index,
                //             label: index,
                //             name: y.title,
                //             style: style,
                //             focusCallback: (x) => handleFocus(null, x),
                //             element: ReactDOMServer.renderToString(<div className="neighbourhood-popup">
                //                 <div className="column">
                //                     <h4>{y.title}</h4>
                //                     <LocationLink location={y.location} minimal />
                //                 </div>
                //             </div>),
                //         })
                //     })
                // })

                // // const mapStyles = organizationMapStyles(organization)
                // index = 0

                // const sidebarId = `neighbourhood-sidebar`
                // return <div id={idx} data-id={id} className={rowClass} data-type="neighbourhood">
                //     {getEditOptions()}
                //     {finalElements && <div id={sidebarId} className="column col-4 scrollable">
                //         {finalTitle && <h3><ReactMarkdownNewline>{finalTitle}</ReactMarkdownNewline></h3>}
                //         {finalBody && <span><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                //         {finalElements?.map((x, ix) => {
                //             const startIndex = index
                //             return <CollapsibleRow id={ix} forceOpen={dataB && dataB > startIndex && dataB <= startIndex + x.elements.length} title={x.title} style="plus" onOpen={(y) => handleGroup(y ? ix : null)}>
                //                 {x.elements.map((y, iy) => {
                //                     index += 1
                //                     const idx = index
                //                     let selectedClass = ''
                //                     if (dataB != null) {
                //                         if (dataB == index) {
                //                             selectedClass = ' focused'
                //                         } else {
                //                             selectedClass = ' unfocused'
                //                         }
                //                     }
                //                     return <React.Fragment>
                //                         {y.heading && <h5 className="neighbourhood-heading" style={{ marginTop: iy > 0 ? '20px' : '' }}>{y.heading}</h5>}
                //                         <span
                //                             id={`neighbourhood-entry-${index}`}
                //                             className={`neighbourhood-entry row${selectedClass}`}
                //                             onMouseOver={(e) => handleMouseOver(e, idx)}
                //                             onMouseLeave={(e) => handleMouseLeave(e, idx)}
                //                             onClick={(e) => handleFocus(e, idx)}>
                //                             <div className="neighbourhood-circle">{index}</div><span>{y.title}</span>
                //                         </span>
                //                     </React.Fragment>
                //                 })}
                //             </CollapsibleRow>
                //         })}
                //     </div>}
                //     <Map
                //         config={dataC}
                //         elements={!dataC ? elements : null}
                //         focusIds={dataB ? [dataB] : []}
                //         focusElement={dataB}
                //         focusElementGroup={data}
                //         styles={mapStyles}
                //         defaultZoom={10}
                //         scrollable={false}
                //         remember={false}
                //         moveToFocus={true}
                //         generate={user.admin}
                //         sidebar={true}
                //         options={{ mapTypeControl: false, streetViewControl: false }}
                //         onFocus={(x) => handleFocus(null, x)}
                //     />


                // </div>
                break
            case DynamicContentType.Steps:
                contentElem = <React.Fragment>
                    <div className="column">
                        {title && <h3><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                        {body && <span className="body"><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}

                        {[...elements].sort((a, b) => a.order - b.order).filter((x, ix) => mobilePortrait || ix == data).map((x, ix) => {
                            let animation = ''
                            if (animated && !mobilePortrait) {
                                animation = ' animate__animated animate__fastest'
                                if (dataB < data) {
                                    animation = `${animation} animate__fadeInRightSmall`
                                } else if (dataB > data) {
                                    animation = `${animation} animate__fadeInLeftSmall`
                                }
                            }
                            return <div className="row element-wrapper" key={x.mediaId}>
                                <div className={`row${animation}`}>
                                    <div className="column col-7">
                                        {x.media && getMedia(x.media)}
                                    </div>
                                    <div className="column col-5">
                                        <span className="step-circle">{mobilePortrait ? ix + 1 : data + 1}</span>
                                        {x.title && <h3 className={titleAnimation}><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h3>}
                                        {x.body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                    </div>
                                </div>
                                {!mobilePortrait && data < elements.length - 1 && <IconButton className="next" noBg icon="fas fa-chevron-right" onClick={() => {
                                    setDataB(data)
                                    setTimeout(() => {
                                        setData(data + 1)
                                    }, 0)
                                }} />}
                                {!mobilePortrait && data > 0 && <IconButton className="prev" noBg icon="fas fa-chevron-left" onClick={() => {
                                    setDataB(data)
                                    setTimeout(() => {
                                        setData(data - 1)
                                    }, 0)
                                }} />}
                            </div>
                        })}
                    </div>
                </React.Fragment>
                break
            case DynamicContentType.Items: {
                let columnSize = 1
                switch (elements?.length) {
                    case 2:
                        columnSize = 6
                        break
                    case 3:
                        columnSize = 4
                        break
                    case 4:
                        columnSize = 3
                        break
                }
                contentElem = <React.Fragment>
                    <div className="column">
                        <div className="row title-row">
                            <div className="column">
                                {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                                {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                            </div>
                            {links && <div className="column" style={{ width: 'auto', justifyContent: 'flex-end' }}>
                                {links.map(getLink)}
                            </div>}
                        </div>
                        <div className="row element-wrapper">
                            {elements?.map((x, ix) => {
                                let finalBody = x.body
                                if (x.expandable && data != ix) {
                                    finalBody = finalBody.slice(0, 155).split(' ')
                                    finalBody.pop()
                                    finalBody = finalBody.join(' ')
                                }
                                return <div className={`column col-${columnSize} ${elementAnimation}`} style={elementStyle(x, ix)}>
                                    {x.logo && <div className="logo-wrapper">{getMedia(x.logo)}</div>}
                                    {x.media && getMedia(x.media, '', null, ThumbSize.Large)}
                                    {x.title && <h4><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h4>}
                                    {x.body && <span className={`body ${x.expandable && data != ix ? 'collapsed' : ''}`}><ReactMarkdownNewline>{finalBody}</ReactMarkdownNewline></span>}
                                    {x.body && x.expandable && <Button noBg className='read-more' onClick={() => setData(data == ix ? null : ix)}>{data == ix ? 'Read Less' : 'Read More'}<Icon noBg icon={`${data == ix ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`} /></Button>}
                                    {x.links && x.links.map((x, ix) => {
                                        return getLink(x, ix)
                                    })}
                                </div>
                            })}
                        </div>
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Media: {
                contentElem = <React.Fragment>
                    {media && <div className={`column col-12 dynamic-content-media clickable ${mediaAnimation}`}>
                        {getMedia(media, '', (mediaId) => handleMedia([mediaId], { mediaOptions: { autoplay: mediaId } }))}
                    </div>}
                </React.Fragment>
                break
            }
            case DynamicContentType.ProjectInfo: {
                forceShow = true
                const projSub = projectSubtitle(app, config)
                const unitConfig = { phase: 1, statsByBuildingType: true }
                contentElem = <React.Fragment>
                    <div className="row content-header">
                        <div className="column col-shrink content-builder">
                            {builder && <Media builder={builder} mediaId={builder.logoMediaId} fadeIn />}
                        </div>
                        <div className="column divider" />
                        <div className="column content-title">
                            <div className="row">
                                <h1>{app.meta.title || app.meta.name}</h1>
                            </div>
                            <div className="row">
                                <h4><ReactMarkdownNewline>{projSub.replace(/\\n/g, "\n")}</ReactMarkdownNewline></h4>
                            </div>
                        </div>
                    </div>
                    <div className="row content-highlight">
                        <div className="column col-4 location-wrapper">
                            <div className="row">
                                {getMapCircle()}
                            </div>
                            <div className="locations">
                                <div className="column">
                                    {app.meta.location && <div className="row">
                                        <div className="column">
                                            <h5>SITE LOCATION</h5>
                                            <LocationLink location={app.meta.location} minimal />
                                        </div>
                                    </div>}
                                    {app.meta.officeLocation?.length > 0 && <div className="row">
                                        <div className="column">
                                            <h5>SALES OFFICE</h5>
                                            <LocationLink location={app.meta.officeLocation[0]} minimal />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="column col-8 highlight-wrapper">
                            <div className={`row content-highlight-card animate__animated animate__fadeIn`} >
                                <div className="column content-highlight-media">
                                    {media && getMedia(media, '', (mediaId) => handleMedia([mediaId], { mediaOptions: { autoplay: mediaId } }))}
                                </div>
                                <div className="column content-highlight-details">
                                    <div className="row builder-media">
                                        {builder && <Media builder={builder} mediaId={builder.logoMediaId} />}
                                    </div>
                                    <div className="row">
                                        <h1>{title}</h1>
                                    </div>
                                    {(subtitle || date || location) && <div className="row">
                                        <div className="column">
                                            {date && <h3 className="content-highlight-date">{fnc.dateFriendly(date, { dayOfTheWeek: true })}</h3>}
                                            {subtitle && <span className="content-highlight-time"><p className="label">Date:</p><ReactMarkdownNewline>{subtitle}</ReactMarkdownNewline></span>}
                                            {location && <span className="content-highlight-location"><p className="label">Location:</p><span>{location.name}<LocationLink location={location.address ? location.address : location} altC /></span></span>}
                                        </div>
                                    </div>}
                                    <div className="content-divider" />
                                    {header && <div className="row event-subtitle">
                                        <h5>{header}</h5>
                                    </div>}
                                    {body && <div className="row event-info">
                                        <span><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>
                                    </div>}
                                    {links?.length > 0 && <div className="row register-link">
                                        {getLink(links[0])}
                                    </div>}
                                </div>
                            </div>
                            {/*unitConfig?.phase && <div className="row content-project-details-heading">
                                <h3>{app.phases.find((x) => x.name.includes(unitConfig.phase.toString()))?.name}</h3>
                            </div>*/}
                            <div className="row content-project-details">
                                {getProjectDetails(unitConfig)}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.ProjectInfo: {
                contentElem = <React.Fragment>
                    <div className="column content-highlight-media">
                        {media && getMedia(media, '', (mediaId) => handleMedia([mediaId], { mediaOptions: { autoplay: mediaId } }))}
                        {/* {eventMediaId && <Media app={app} mediaId={eventMediaId} thumb thumbSize={ThumbSize.Large} onClick={() => handleMedia(eventMediaId)} fadeIn onLoad={() => { */}
                        {/* setHighlightLoaded(true) */}
                        {/* }} />} */}
                        {/* {!eventMediaId && !highlightLoaded && (() => setHighlightLoaded(true))()} */}
                    </div>

                    <div className="column content-highlight-details">
                        <div className="row builder-media">
                            {builder && <Media builder={builder} mediaId={builder.logoMediaId} />}
                        </div>
                        <div className="row">
                            <h1>{title}</h1>
                        </div>
                        {(subtitle || date || location) && <div className="row">
                            <div className="column">
                                {date && <h3 className="content-highlight-date">{fnc.dateFriendly(date, { dayOfTheWeek: true })}</h3>}
                                {subtitle && <span className="content-highlight-time"><p className="label">Date:</p><ReactMarkdownNewline>{subtitle}</ReactMarkdownNewline></span>}
                                {location && <span className="content-highlight-location"><p className="label">Location:</p><span>{location.name}<LocationLink location={location.address ? location.address : location} altC /></span></span>}
                            </div>
                        </div>}
                        <div className="content-divider" />
                        {header && <div className="row event-subtitle">
                            <h5>{header}</h5>
                        </div>}
                        {body && <div className="row event-info">
                            <span><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>
                        </div>}
                        {/*<div className="row register-link">
                        <Link to={`${projectPath}${app.meta.link}/register`}>
                            <span>Register Now</span>
                        </Link>
                    </div>*/}

                        {links.length > 0 && <div className="row register-link">
                            {getLink(links[0])}
                        </div>}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Quotes: {
                contentElem = <React.Fragment>
                    <div className="column">
                        {elements && elements.map((x, ix) => {
                            // let body = null
                            // if (x.elements.length > 0) {
                            // body = x.elements[0]
                            // }
                            return <React.Fragment>
                                {/* {ix < elements.length - 1 && <div className="row content-divider" />} */}
                                {<div className="row content-divider" />}
                                <div className="row quote-group">
                                    <div className="column col-8">
                                        {x.title && <h3><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h3>}
                                        {x.body && <span className="body"><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                    </div>
                                    <div className="column col-4">
                                        <div className="row quote">
                                            <div className="column">
                                                {x.header && <span className="quote-body"><ReactMarkdownNewline>{x.header}</ReactMarkdownNewline></span>}
                                                {x.title && <h4 className="author">{x.title}</h4>}
                                                {x.subtitle && <h4 className="organization">{x.subtitle}</h4>}
                                                <div className="bottom-right">
                                                    <Icon noBg icon="fas fa-quote-right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row portrait">
                                            {x.media && getMedia(x.media)}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        })}
                    </div>
                </React.Fragment>
                break
            }
            case DynamicContentType.Points: {
                const parsed = parseMedia(media)
                if (!parsed) {
                    return null
                }
                const mainMedia = parsed.data[0]
                const mediaSize = media[0]?.size ? media[0].size : 4
                contentElem = <React.Fragment>
                    <div className="column scrollable">
                        {!screen.isMobile && (title || subtitle || supertitle || titleMedia) && <div className={`row content-title${titleMedia ? ' with-media' : ''}`}>
                            {supertitle && <h4>{supertitle}</h4>}
                            {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                            {subtitle && <h4>{subtitle}</h4>}
                            {titleMedia && getMedia(titleMedia)}
                        </div>}
                        {header && <span className="header"><i>{header}</i></span>}
                        {body && <span className={`body ${bodyAnimation}`}><ReactMarkdown linkTarget="_blank">{body}</ReactMarkdown> </span>}
                        {elements && <div className="content-points">
                            {elements.map((x, ix) => {
                                return <React.Fragment>
                                    <div className={`row content-point ${data == ix ? 'selected' : ''}`} key={ix} onClick={() => handleToggleData(ix)}>
                                        <span className="circle">{(ix + 1).toString()}</span><span>{x.title}</span>
                                    </div>
                                    {data == ix && (x?.media?.mediaId || x.body) && <div className="row content-point-data">
                                        <div className="column" style={{ minHeight: size ? `${size}px` : null }}>
                                            {x.media && getMedia(x.media, null, null, ThumbSize.Medium, true)}
                                        </div>
                                        <div className="column" style={{ minHeight: size ? `${size}px` : null }}>
                                            {x.body && <span><ReactMarkdownNewline>{x.body}</ReactMarkdownNewline></span>}
                                        </div>
                                    </div>}
                                </React.Fragment>
                            })}
                        </div>}
                        {links && <div className="dynamic-content-links row">
                            {links.map(getLink)}
                        </div>}
                        {footer && <h4 className="content-footer">{footer}</h4>}
                    </div>
                    {mainMedia && <div className={`column col-${mediaSize} dynamic-content-media${(mainMedia.mediaTypeId > MediaType.Image || mainMedia.clickable) ? ' clickable' : ''} ${mediaAnimation}`} data-type={mainMedia.type}>
                        <MediaIcon media={mainMedia} />
                        {screen.isMobile && (title || subtitle || supertitle || titleMedia) && <div className={`row content-title${titleMedia ? ' with-media' : ''}`}>
                            {supertitle && <h4>{supertitle}</h4>}
                            {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                            {subtitle && <h4>{subtitle}</h4>}
                            {titleMedia && getMedia(titleMedia)}
                        </div>}
                        <div className="row">
                            {getMedia(mainMedia, '', (mainMedia.clickable || mainMedia.mediaTypeId != null) ? () => handleMedia(mainMedia) : null, mediaSize == 8 ? ThumbSize.ExtraLarge : ThumbSize.Large, true)}
                            {elements?.length > 0 && <div className="dynamic-content-media-points">
                                {elements.map((elem, ix) => {
                                    if (!elem.points) {
                                        return null
                                    }
                                    return elem.points.map((point, iy) => {
                                        return <DynamicContentPoint point={point} editing={editing} selected={data} idxA={ix} idxB={iy} onClick={handleToggleData} onEdit={(x, y) => {
                                            window.dispatchEvent(new CustomEvent(CustomEventType.EditPoint, { detail: { element: elem, point: { ...point, x, y } } }))
                                        }} />
                                    })
                                })}
                            </div>}
                        </div>
                    </div>}
                </React.Fragment>
                break
            }
            case DynamicContentType.ScreenSaver: {
                // const gallery = app.galleries.find((x) => x.link == 'screensaver')
                // if (!gallery) {
                // return null
                // }
                const pageAnimation = 'animate__animated animate__fadeIn'
                return <div id="screensaver-page" className={`${pageAnimation}`}>
                    {getMedia(media)}
                    {/* <SlideShow app={app} media={gallery.media.map((x) => x.mediaId)} zoomable={false} autoScroll={8000} showIndex={false} /> */}
                    <WaterMark />
                </div>
            }

            case DynamicContentType.Footer: {
                const renderElement = (x) => {
                    const locations = x.locations ? x.locations.map((x) => {
                        if (x.locationId) {
                            return organization.locations.find((y) => y.id == x.locationId)
                        } else {
                            return x
                        }
                    }) : []
                    // Encode new line strings as new line characters
                    let body = x.body
                    // key = `${key}${additionalSource?.refresh}`
                    if (x.dynamicContentTypeId == DynamicContentType.RequestInfo) {
                        return <RequestInfo app={app} organization={organization} forceOpen={true} />
                    }
                    return <React.Fragment>
                        {x.title && <h5>{x.title}</h5>}
                        {x.subtitle && <div className="row"><h3>{x.subtitle}</h3></div>}
                        {body && <div className="row"><div className="column"><ReactMarkdown remarkPlugins={[gfm]}>{body}</ReactMarkdown></div></div>}
                        {
                            (x.links?.length > 0 || locations?.length > 0) && <div className="row" style={{ marginBottom: '20px' }}>
                                <div className="column">
                                    {locations?.map((x) => <LocationLink location={x} alt />)}
                                    {x.links?.map(getLink)}
                                </div>
                            </div>
                        }
                        {x.elements?.map(renderElement)}
                    </React.Fragment>
                }
                return <div className="column col-3">
                    {getEditOptions()}
                    {getDraftBadge()}
                    {renderElement(Object.keys(additionalSource).length > 0 ? additionalSource : props)}
                </div>

            }
            case DynamicContentType.RecentBlog: {
                let columnSize = 1
                // let posts = blogPosts(app, organization)?.filter((x) => {
                //     if (tags?.length > 0) {
                //         let tagListA = tags.split(',')
                //         let tagListB = x.tags ? x.tags.split(',') : []
                //         return new Set(tagListA).intersection(new Set(tagListB)).size == tagListA.length
                //     }
                //     return true
                // })
                let posts = data ? [...data].slice(0, gridLimit) : []
                let contentStyleString = ''
                switch (contentStyle) {
                    case 0: // Row
                        // posts = posts.slice(0, 4 * Math.max(size, 1))
                        if (posts.length < 4) {
                            columnSize = 4
                        } else {
                            columnSize = 3
                        }
                        contentStyleString = 'row'
                        break
                    case 1: // Grid
                        // posts = posts.slice(0, 2 * Math.max(size, 1))
                        columnSize = 6
                        contentStyleString = 'grid'
                        break
                    case 2: //Feature
                        // posts = posts.slice(0, 4)
                        contentStyleString = 'feature'
                        columnSize = 12
                        break
                }

                let recentElements = []

                posts.forEach((x) => {
                    const content = getHybridContent(app, builder, organization, x, {}, null, true)
                    const orderSortedContent = [...content].sort((a, b) => a.order - b.order)
                    const previewContent = orderSortedContent.length > 0 ? orderSortedContent[0] : null
                    let element = {
                        title: x.name,
                        datePublished: x.datePublished ? new Date(x.datePublished) : null,
                    }
                    if (previewContent) {
                        if (previewContent.media.length > 0) {
                            element.media = [{ ...previewContent.media[0], clickable: false }]
                        }
                        element.title = previewContent.title
                        element.subtitle = previewContent.subtitle
                        let body = previewContent.body.split('\n')[0]
                        const characterLimit = 200
                        if (body.length > characterLimit) {
                            body = body.substring(0, characterLimit) + '...'
                        }
                        element.body = body
                    }
                    element.link = {
                        pageId: x.id,
                        icon: "fas fa-arrow-right",
                        label: 'Read more',
                        relative: true,
                    }
                    recentElements.push(element)
                })

                let featureElement = null
                switch (contentStyle) {
                    case 0: // Row
                        break
                    case 1: // Grid
                        break
                    case 2: //Feature 
                        {
                            if (recentElements.length > 0) {
                                featureElement = recentElements[0]
                                recentElements = recentElements.slice(0, 3)
                            }
                        }
                        break
                }
                /*switch (recentElements?.length) {
                    case 2:
                        columnSize = 6
                        break
                    case 3:
                        columnSize = 4
                        break
                    case 4:
                        columnSize = 3
                        break
                }*/

                const getElement = (x, ix, horizontal = false, divider = false,characterLimit = 100) => {
                    let finalBody = x.body
                    if (screen.isMobile && screen.orientation == ScreenOrientation.Portrait && horizontal) {
                        horizontal = false
                        divider = true
                    }
                    // if (x.expandable && data != ix) {
                    // finalBody = finalBody.slice(0, 155).split(' ')
                    // finalBody.pop()
                    // finalBody = finalBody.join(' ')
                    // }
                    finalBody = finalBody.split('\n')[0]
                    if (finalBody.length > characterLimit) {
                        finalBody = finalBody.substring(0, characterLimit) + '...'
                    }
                    // Format date as day / month / year
                    const dateString = x.datePublished ? `${x.datePublished.getDate()} / ${x.datePublished.getMonth()} / ${x.datePublished.getFullYear()}` : ''
                    const mediaElem = x.media ? getMedia(x.media, '', null, ThumbSize.Large) : null
                    const bodyElem = <React.Fragment>
                        {x.title && <h4><ReactMarkdownNewline>{x.title}</ReactMarkdownNewline></h4>}
                        {x.body && <span className={`body ${x.expandable && data != ix ? 'collapsed' : ''}`}><ReactMarkdownNewline>{finalBody}</ReactMarkdownNewline></span>}
                        {x.body && x.expandable && <Button noBg className='read-more' onClick={() => setData(data == ix ? null : ix)}>{data == ix ? 'Read Less' : 'Read More'}<Icon noBg icon={`${data == ix ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}`} /></Button>}
                        {x.links && x.links.map((x, ix) => {
                            return getLink(x, ix)
                        })}
                        <div className="row blog-footer">
                            <span style={{ textDecoration: 'underline' }}>Read More</span>
                            {x.datePublished && <span className="date-published">{dateString}</span>}
                        </div>
                    </React.Fragment>

                    let elem = null
                    let className = `column col-${columnSize}`
                    if (horizontal) {
                        if (contentStyleString == "feature") {
                            className = `${className} row`
                        }
                        className = `${className} horizontal`
                        elem = <React.Fragment>
                            <div className="column">
                                {mediaElem}
                            </div>
                            <div className="column">
                                {bodyElem}
                            </div>
                        </React.Fragment>
                    } else {
                        elem = <React.Fragment>
                            {mediaElem}
                            {bodyElem}
                        </React.Fragment>
                    }
                    className = `${className} blog-post ${elementAnimation}${divider ? ' divider' : ''}`
                    return <Link to={getLinkUrl(x.link)[0]} className={className} style={elementStyle(x, ix)}>
                        {/* <div className={className} style={elementStyle(x, ix)}> */}
                        {elem}
                    </Link>
                }

                contentElem = <React.Fragment>
                    <div className="column">
                        <div className="row title-row">
                            <div className="column">
                                {title && <h3 className={titleAnimation}><ReactMarkdownNewline>{title}</ReactMarkdownNewline></h3>}
                                {body && <span className={`body ${bodyAnimation}`}><ReactMarkdownNewline>{body}</ReactMarkdownNewline></span>}
                            </div>
                            {links && <div className="column" style={{ width: 'auto', justifyContent: 'flex-end' }}>
                                {links.map(getLink)}
                            </div>}
                        </div>
                        {featureElement && <div className="row element-wrapper" data-style={contentStyleString}>
                            {featureElement && <div className="column feature">
                                {getElement(featureElement, 0, false, false, 200)}
                            </div>}
                            <div className="column">
                                <h3>Featured Articles</h3>
                                {recentElements.map((x, ix) => getElement(x, ix, true, true))}
                            </div>
                        </div>}
                        {!featureElement && <div className="row element-wrapper" data-style={contentStyleString}>
                            {recentElements.map((x, ix) => getElement(x, ix, contentStyleString == 'grid', false))}
                        </div>}
                    </div>
                </React.Fragment>
                break
            }
            default:
                break
        }
        if (forceShow) {
            rowClass = `${rowClass} visible`
        }
        return <div id={anchor ? anchor : idx} key={key} data-id={id} className={rowClass} data-inviewport={animated && !mobilePortrait} data-type={DynamicContentType[finalType]?.toLowerCase()} style={rowStyle} ref={setRowRef}>
            {getEditOptions()}
            {getDraftBadge()}
            {contentElem}
            {collapseElem}
        </div>
    }

    return <React.Fragment>
        {/* {dividerBefore && <div className="row content-divider" />} */}
        {getContent()}
        {dividerAfter && <div className="row content-divider" />}
    </React.Fragment>
}

